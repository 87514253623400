import React from "react"
import { useEffect, useMemo } from "react"
import { Box } from "rebass"
import useHomeContext from "../../logic/providers"
import DeviceItem from "../../ui/deviceItem/diviceItem"
import DeviceItemAdd from "../../ui/deviceItem/diviceItemAdd"


const Tram = () => {

    const { controlButtons} = useHomeContext()


    const ButtonControls = useMemo(() => {
        if (!controlButtons || !controlButtons?.length) {
            return <DeviceItemAdd />
        }

        return (
            <>
                <div className="box__device_content">
                    {
                        controlButtons?.map((btn: any, idx: number) => {
                            const btnIndex = idx%4 === 0 && 4 || idx%3 === 0 && 3 || idx%2 ===0 && 2 || 1

                            return (
                                <div style={{ display: 'inline-block', marginRight: '10px'}} key={idx}>
                                    <DeviceItem  item={btn} index={btnIndex} />
                                </div>
                            )
                        })
                    }
                     <div style={{ display: 'inline-block', marginRight: '10px'}}>
                            <DeviceItemAdd key={controlButtons?.length} />
                    </div>
                </div>
            </>
        )
    }, [controlButtons])

    return (
        <Box variant='box__devices'>
            <Box variant='box_item'>
                <Box variant='box__device_content' >
                    {ButtonControls}
                </Box>
            </Box>
        </Box>
    )
}

export default React.memo(Tram)
