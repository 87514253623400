import { useMediaQuery } from 'react-responsive'
import { IResponsive } from '../types'

export const useResponsive = (): IResponsive => {
  const isMobile = useMediaQuery({ maxWidth: '767px' })
  const isTablet = useMediaQuery({ maxWidth: '1023px' })
  const isDesktop = useMediaQuery({ minWidth: '1024px' })

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
}
