import Login from '../modules/auth/login.page'
import Signup from '../modules/auth/signup.page'
import Home from '../modules/home/index.page'
import Tram from '../modules/home/subPages/Tram'
import { Home2 } from '../modules/home2/index.page'
import { ClientSystem } from '../modules/system/index.page'
import { IRoute } from '../types'

export const routes: IRoute[]  = [
  {
    path: '/',
    component: Home,
    isExact: true,
  },
  {
    path: '/home2',
    component: Home2,
    isExact: false,
  },
  {
    path: '/login',
    component: Login,
    isExact: false,
  },
  {
    path: '/register',
    component: Signup,
    isExact: false,
  },
  // {
  //   path: '/client/system',
  //   component: ClientSystem,
  //   isExact: false,
  // },
  {
    path: '/client/system/tram',
    component: Tram,
    isExact: true,
  }
]
