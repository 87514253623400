import { Input } from "@rebass/forms";
import { FormikValues, useFormik } from "formik"
import { Box, Text } from "rebass"
import * as Yup from 'yup'
import { Button, Modal, notification, Select, Select as SelectAntd } from 'antd';
import Form from "../../../../../components/form/form";
import useHomeContext from "../../../logic/providers";
import FieldHOC from "../../../../../components/form/input";
import { getFormErrorMessage } from "../../../../../helpers/getFormErrorMessage";
import { events } from "../../../../../constant";
const { Option } = SelectAntd;

interface IProps {
    item?: any;
    onOk: (data: any) => void;
    onCancel: () => void;
    initialValues?: FormikValues
}

const validationSchema = Yup.object().shape({
    buttonName: Yup.string().required('Field required!'),
    icon: Yup.string(),
    className: Yup.string(),
    valueOn: Yup.number().required('Field required!'),
    valueOff: Yup.number().required('Field required!'),
    eventName: Yup.string(),
})


const DeviceDetail = ({ onOk, item, onCancel }: IProps) => {
    const { controlButtons, deleteButton } = useHomeContext()

    const initialValues = {
        buttonName: item?.buttonName || '',
        icon: item?.icon || 'AiOutlineMeh',
        className: item?.className || 'className',
        valueOn: item?.valueOn || 0,
        valueOff: item?.valueOff || 0,
        eventName: item?.eventName || 'SEND',
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (data: any) => {
            onOk(data)
        }
    })

    const isExitStartEvent = controlButtons?.length && !!controlButtons.find((btn: any) => btn.eventName === 'START')
    const isExitStopEvent = controlButtons?.length && !!controlButtons.find((btn: any) => btn.eventName === 'STOP')

    const onEventNameChange = (value: any) => {
        if (!value || (isExitStartEvent && value === 'START') || (isExitStopEvent && value === 'STOP')) return

        formik.setFieldValue('eventName', value)
    }

    const onDeleted = (btnID: string) => {
        Modal.confirm({
            title: `Bạn muốn xóa button ${item?.buttonName} ?`,
            onOk: () => {
                deleteButton(btnID).then(() => {
                    notification.success({
                        message: 'Xóa thành công!'
                    })
                }).catch((error: any) => {
                    if (error.response) {
                        notification.error({
                            message: `${error.response.data?.error}`,
                            placement: 'bottomRight',
                        });
                    }
                }).finally(() => onCancel())
            }
        })
    }

    return (
        <Box variant='box__system_config'>
            <Form
                key='form-use-fomik'
                handleSubmit={formik.handleSubmit}
                handleReset={formik.handleReset}
            >
                <>
                    <Box variant='box__system_config_container'>
                        {/* <Box variant='box__system_config_header'>
                            <Text variant='text__system_config_title'>Button Control: </Text>
                        </Box> */}
                        <Box className='box_device_detail_content'>
                            <Box>
                                <FieldHOC
                                    name='eventName'
                                    id='eventName'
                                    errorMessage={getFormErrorMessage({ error: formik?.errors?.eventName, touched: formik?.touched?.eventName })}
                                    errorVariant='text__form_valid_errors'
                                    labelVariant='label__form_input'
                                    label="Tên even: "
                                >
                                    <div className="wrapper">
                                        <Select defaultValue={formik?.values?.eventName} onChange={onEventNameChange}>
                                            {events?.map((item: any, idx: number) => (
                                                <Option
                                                    key={`${item}_${idx}`}
                                                    disabled={(isExitStartEvent && item === 'START') || (isExitStopEvent && item === 'STOP')}
                                                    value={item}
                                                >
                                                    {item}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>

                                </FieldHOC>
                            </Box>
                            <Box className="box_device_detail_content__input box_device_detail_content__name">
                                <FieldHOC
                                    name='buttonName'
                                    id='buttonName'
                                    errorMessage={getFormErrorMessage({ error: formik?.errors?.buttonName, touched: formik?.touched?.buttonName })}
                                    errorVariant='text__form_valid_errors'
                                    labelVariant='label__form_input'
                                    label='Tên button: '
                                >
                                    <Input
                                        name='buttonName'
                                        placeholder=''
                                        type='text'
                                        value={formik?.values?.buttonName}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        variant='input__form_info' />
                                </FieldHOC>
                            </Box>
                            <Box className="box_device_detail_content__input">
                                <FieldHOC
                                    name='icon'
                                    id='icon'
                                    errorMessage={getFormErrorMessage({ error: formik?.errors?.icon, touched: formik?.touched?.icon })}
                                    errorVariant='text__form_valid_errors'
                                    labelVariant='label__form_input'
                                    label='Icon '
                                >
                                    <Input
                                        name='icon'
                                        placeholder=''
                                        type='text'
                                        disabled={true}
                                        value={formik?.values?.icon}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        variant='input__form_info' />
                                </FieldHOC>
                            </Box>
                            {/* <Box className="box_device_detail_content__input">
                            <FieldHOC
                                name='className'
                                id='className'
                                errorMessage={getFormErrorMessage({ error: formik?.errors?.className, touched: formik?.touched?.className })}
                                errorVariant='text__form_valid_errors'
                                labelVariant='label__form_input'
                                label='ClassName: '
                            >
                                <Input
                                    name='className'
                                    placeholder=''
                                    type='text'
                                    value={formik?.values?.className}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    variant='input__form_info' />
                            </FieldHOC>
                        </Box> */}
                            <div className='box_device_detail_button_value'>
                                <Box className="box_device_detail_content__on">
                                    <FieldHOC
                                        name='valueOn'
                                        id='valueOn'
                                        errorMessage={getFormErrorMessage({ error: formik?.errors?.valueOn, touched: formik?.touched?.valueOn })}
                                        errorVariant='text__form_valid_errors'
                                        labelVariant='label__form_input'
                                        label='On: '
                                    >
                                        <Input
                                            name='valueOn'
                                            placeholder='1'
                                            type='number'
                                            value={formik?.values?.valueOn}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            variant='input__form_info' />
                                    </FieldHOC>
                                </Box>
                                <Box className="box_device_detail_content__off">
                                    <FieldHOC
                                        name='valueOff'
                                        id='valueOff'
                                        errorMessage={getFormErrorMessage({ error: formik?.errors?.valueOff, touched: formik?.touched?.valueOff })}
                                        errorVariant='text__form_valid_errors'
                                        labelVariant='label__form_input'
                                        label='Off'
                                    >
                                        <Input
                                            name='valueOff'
                                            placeholder='0'
                                            type='number'
                                            value={formik?.values?.valueOff}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            variant='input__form_info' />
                                    </FieldHOC>
                                </Box>
                            </div>
                        </Box>
                    </Box>

                    <Box variant='box__device_item_footer' className="box___device_item_footer">
                        <Button className="btn__system_config_reset" onClick={(e: any) => {
                            onCancel()
                            formik.handleReset(e)
                        }}>Hủy</Button>

                        <div className="btn_system_button_control_right">
                            {item?.buttonID ? <Button className="btn__system_config_delete" onClick={(e: any) => {
                                e.preventDefault()
                                onDeleted(item?.buttonID || '')
                            }}>Xóa</Button> : null}
                            <Button
                                className="btn__system_config_save"
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    formik.handleSubmit()
                                    e.stopPropagation()
                                }}
                            >
                                Lưu
                            </Button>
                        </div>
                    </Box>
                </>
            </Form>
        </Box>
    )
}

export default DeviceDetail;
