import { useEffect } from "react"
import { useHistory } from "react-router-dom"

export const Layout = ({children}:any) => {
    const history = useHistory()

    useEffect(() => {
        const token = localStorage.getItem('token')
        const isAuthenticated = !!token

        if (isAuthenticated) {
            return;
        }
        
        return history.push('/login')
    }, [localStorage.getItem('token')])

    return (
        <>
        {children}
        </>
    )
}
