import { Box, Button, Text } from "rebass"
import { AiOutlineSliders } from "react-icons/ai";
import { useEffect, useState } from "react";
import useHomeContext from "../../../logic/providers";
import './style.css'
import ConnectStatus from "./connectStatus";

interface IProps {
    setIsConfig: (isConfig: boolean) => void
}

const Header = ({setIsConfig}: IProps) => {
    const { currentDeviceID, getDeviceByID, currentDevice} = useHomeContext()

    useEffect(() => {
        if (!currentDeviceID) return

        getDeviceByID(currentDeviceID)
    }, [currentDeviceID])
    
    return (
        <Box variant='box__header'>
            <Box className="header-left">
                <Text variant='text__header_account_name'>{currentDevice?.deviceName || '___'}</Text>
               <ConnectStatus />
            </Box>
            <Button variant='btn__header_config' onClick={() => setIsConfig(true)}>
                <span>Config</span>
                <AiOutlineSliders color='#91979b' width={25} height={25} />
            </Button>
        </Box>
    )
}

export default Header;