import React, { createContext, useCallback, useContext, useRef, useState } from 'react'
import { TAB_TYPE_ENUM } from '../../../constant'
import { IHomeContextProps, IInfo } from '../../../types'
import { onControlButtonDelete, onCreateControlButton, onCreateDevice, onCreateSchedules, onDeletedtLogs, onDeletedtSchedule, onGetControlButton, onGetDevices, onGetLogs, onGetSchedules, onUpdateControlButton, onUpdateDevice, onUpdateSchedules } from './api'

export const HomeContext = createContext<IHomeContextProps | null>(null)

export const HomeProvider: React.FC = ({ children }) => {
  const [socket, setSocket] = useState<any>(null)
  const [currTap, setCurrTap] = useState(localStorage.getItem('tap') || TAB_TYPE_ENUM.HOME)
  const [title, setTitle] = useState('')
  const [devices, setDevices] = useState<any>([])
  const [currentDevice, setCurrentDevice] = useState<any>()
  const [currentDeviceID, setCurrentDeviceID] = useState<string | null>(null)
  const [token, setToken] = useState('')
  const [logs, setLogs] = useState<any>([])
  const [zoom, setZoom] = useState<number>(1)

  const [controlButtons, setControlButtons] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)

  const [schedules, setSchedules] = useState<any>([])
  const [currSchedule, setCurrSchedule] = useState<any>(null)

  const getDevices = useCallback((params?: any) => {
    return onGetDevices(params).then((res: any) => {
      if (res?.status !== 200 || !res?.data?.devices?.length) {
        setDevices([])

        return
      }

      setDevices(res?.data?.devices)
    })
  }, [])

  const getDeviceByID = useCallback((deviceID: string) => {
    return onGetDevices({ deviceID }).then((res: any) => {
      if (res?.status !== 200 || !res?.data?.devices?.length) {
        setDevices([])

        return
      }

      setCurrentDevice(res?.data?.devices[0])
      // setControlButtons(res?.data?.devices[0]?.controlButtons || [])

      return res?.data?.devices[0]
    })
  }, [])

  const createDevice = useCallback((data: any) => {
    onCreateDevice(data).then((res:any) => {
      if (res?.status !== 201) {
        Notification
        return
      }

      setDevices([...devices, ...data])
    })
  }, [devices])

  const updateDevice = useCallback((data: any, deviceID: string) => {
    return onUpdateDevice(data, deviceID).then((res:any) => {
      if (res?.status !== 200) {
        Notification
        return
      }

      const dataUpdate = {
        ...currentDevice,
        irrConfig: {
          ...currentDevice?.irrConfig,
          ...data?.irrConfig,
        }
      }

      setCurrentDevice({...dataUpdate})

      return res
    })
  }, [devices, currentDevice])

  const getButtons = useCallback(({ deviceID, tab}: { deviceID: string, tab: string}) => {
    setIsLoading(true)
    return onGetControlButton({tab, deviceID}).then((res: any) => {

      if (res?.status !== 200) {

        return
      }


      setControlButtons([...res?.data])
      setIsLoading(false)
    })
  }, [])

  const createButton = useCallback((data: any) => {
    return onCreateControlButton(data).then((res:any) => {
      if (res?.status !== 200 || !res.data.result) {
        return
      }

     controlButtons?.push(res.data.result)

      setControlButtons([...controlButtons])

      return res
    })
  }, [controlButtons])

  const updateButton = useCallback((data: any) => {
    return onUpdateControlButton(data).then((res:any) => {
      if (res?.status !== 200) {
        Notification
        return
      }

        const tempControlButtons = controlButtons.map((item: any) => {
          if (item?.buttonID === data?.buttonID) {
            return data
          }
  
          return item
        }) || []

      setControlButtons([...tempControlButtons])

      return res
    })
  }, [controlButtons])

  const deleteButton = useCallback((btnID: any) => {
    return onControlButtonDelete(btnID).then((res:any) => {
        const tempControlButtons = controlButtons?.filter((item: any) => item?.buttonID !== btnID) || []

      setControlButtons([...tempControlButtons])

      return res
    })
  }, [controlButtons])

  const getLogs = useCallback((deviceID: string) => {
    return onGetLogs(deviceID).then((res:any) => {
      return res.data
    })
  }, [logs])

  const deletedLogs = useCallback((deviceID: string) => {
    return onDeletedtLogs(deviceID).then((res) => {
      if (res) {
        setLogs([])
      }

      return res
      
    })
  }, [logs])

  const getShedules = useCallback((btnID: string) => {
    return onGetSchedules({btnID, currentDeviceID}).then((res) => {
      const tempData = res?.data?.filter((item: any) => !!item?.scheduleDevicesID)

      return tempData || []
    })
  }, [schedules, currentDeviceID])

  const updateSchedule = useCallback(({ data, scheduleID}: any) => {
    return (
          onUpdateSchedules({ scheduleID, data})
            .then((res) => {
              if (res?.data) {
                const tempShedule = schedules.map((val: any) => {
                  if (val?.scheduleDevicesID === scheduleID) {
                    return res.data
                  }

                  return val
                })

                setSchedules(tempShedule)
                setCurrSchedule(res?.data)
              }

              return res?.data
            }
          )
        )
  }, [currSchedule, schedules])

  const deleteSchedule = useCallback((scheduleID: string) => {
    return (
      onDeletedtSchedule({scheduleID})
        .then((res) => {
          if (res.data) {
            const temp= schedules?.filter((item: any) => item?.scheduleDevicesID !== scheduleID) || []
            setSchedules([...temp])
          }

          return res.data
        })
    )
  }, [schedules])

  const createSchedule = useCallback(({data}: any) => {
    return onCreateSchedules({ data}).then((res) => {
      if (res?.status !== 200 || !res.data) {
        return
      }

      setSchedules((prev: any) => {
        return [...prev, res?.data]
      })

      return res
    })
  }, [schedules])

  return (
    <HomeContext.Provider
      value={{
        title,
        setTitle,
        token, 
        setToken,
        devices,
        setDevices,
        currentDevice,
        setCurrentDevice,
        getDevices,
        getDeviceByID,
        createDevice,
        updateDevice,
        logs,
        setLogs,
        socket,
        setSocket,
        updateButton,
        createButton,
        controlButtons,
        setControlButtons,
        zoom,
        setZoom,
        deleteButton,
        currTap,
        setCurrTap,
        getButtons,
        currentDeviceID,
        setCurrentDeviceID,
        getLogs,
        deletedLogs,
        isLoading,
        setIsLoading,
        schedules,
        setSchedules,
        currSchedule,
        setCurrSchedule,
        getShedules,
        updateSchedule,
        deleteSchedule,
        createSchedule
      }}
    >
      {children}
    </HomeContext.Provider>
  )
}

export default function useHomeContext() {
  const context = useContext(HomeContext)
  if (!context) {
    throw new Error('useHomeContext must be used within a HomeProvider')
  }

  return context
}