import { FormikErrors } from 'formik'
import { Box, Text } from 'rebass'
import { Label } from '@rebass/forms'

export interface IInputFieldProps<Values> {
  id: string,
  isRequired?: boolean,
  errorMessage?:
  | string
  | string[]
  | FormikErrors<Values>
  | FormikErrors<Values>[],
  label?: string,
  name: string,
  hidden?: boolean,
  helperText?: string,
  leading?: JSX.Element | string,
  trailing?: JSX.Element,
  fieldVariant?: string,
  labelVariant?: string,
  errorVariant?: string,
  topVariant?: string,
  contentVariant?: string,
  trailingVariant?: string,
  children?: JSX.Element,
  disabled?: boolean,
  readOnly?: boolean,
  note?: JSX.Element,
}

function FieldHOC<Values>({
  id,
  isRequired,
  hidden,
  name,
  errorMessage,
  label,
  helperText,
  disabled = false,
  leading,
  trailing,
  readOnly = false,
  fieldVariant,
  errorVariant,
  contentVariant,
  trailingVariant,
  children,
  labelVariant,
  note,
  topVariant,
}: IInputFieldProps<Values>) {
  return (
    <Box id={id} variant={fieldVariant} required={isRequired} hidden={hidden}>
      <Box variant={topVariant}>
          {label && <Label htmlFor={name} variant={labelVariant}>{label}</Label>}
          {note && note}
      </Box>
      <Box variant={contentVariant}>
        {leading && (
          <Box color='gray.300' fontSize='1.2em' sx={{ pointerEvents: 'none' }}>
            {leading}
          </Box>
        )}
        {children}
        {trailing && !disabled && !readOnly && (
          <Box variant={trailingVariant}>
            {trailing}
          </Box>
        )}
      </Box>
      {helperText && <Text>{helperText}</Text>}
      <Text fontSize='xs' mt={1} variant={errorVariant}>{errorMessage}</Text>
    </Box>
  )
}

export default FieldHOC
