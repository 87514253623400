export const textarea = {
  textarea__form: {
    width: '100%',
    resize: 'vertical',
    minWidth: 'max-content',
    fontSize: '16px',
    lineHeight: 'inherit',
    padding: '8px 0 8px 20px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    color: '#3e4450',
    height: '50px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    backgroundColor: 'rgb(232, 240, 254)',
    '&:placeholder': {
      color: '#7d8db3',
      verticalAlign: 'bottom',
    },
    '&:focus-visible': {
      border: '0.5px solid red',
      outline: 'none',
    }
  }
}