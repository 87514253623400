import React, { useMemo } from  'react'
import { Button, Box, Text } from 'rebass'
import { IHome2ContextProps, IHomeContextProps, IResponsive } from '../../types'
import { useResponsive } from '../../ultis/useResponsive'
import useHomeContext from '../home/logic/providers'
import useHome2Context from './logic/providers'

interface IProps {
  children?: React.ReactNode
}

export const Home2: React.FC<IProps> = () => {
  const { title }: IHome2ContextProps = useHome2Context()
  const { setTitle }: IHomeContextProps = useHomeContext()
  const { isMobile, isTablet }: IResponsive = useResponsive();

  const buttonLabel = useMemo(() => {
    if (isMobile) {
      return 'Mobile';
    }
    if (isTablet) {
      return 'Tablet';
    }

    return 'Desktop';
  }, [isMobile, isTablet]);

  return (
    <div>
      <p>{title}</p>
      <Button
        variant='btn.rounded'
        sx={{
          backgroundColor: '#00F',
        }}
        width={[ '96px', '140px', '350px']}
        onClick={() => setTitle('Home 2')}
      >
        {buttonLabel}
      </Button>

      <Box
        height={[300, 200, 100]}
        mt={10}
        overflow='scroll'
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          }
        }}
      >
        {Array.from({length: 30}, (_, idx) => {
          return <Text height={30} key={idx}>Item {idx}</Text>
        })}
      </Box>
    </div>
  )
}
