import React, { useCallback, useEffect, useMemo, useState } from  'react'
import { Box } from 'rebass'
import { io } from 'socket.io-client'
import useHomeContext from './logic/providers'
import Footer from './ui/layout/Footer/footer'
import Header from './ui/layout/Header/header'
import useAuthContext from '../auth/logic/providers'
import './ui/style.css'
import Home from './subPages/Home'
import Tram from './subPages/Tram'
import { SOCKET_EVENT, socket_host, TAB_TYPE_ENUM } from '../../constant'
import ThietBi from './subPages/ThietBi'
import moment from 'moment'
import { Modal, notification } from 'antd'
import { useHistory } from 'react-router-dom'
import SystemConfig from './ui/layout/Modal/system-config'
import { SocketClient } from '../../socket/socketIo'

interface IProps {
  children?: React.ReactNode
}

const System: React.FC<IProps> = () => {
  const history = useHistory()
  const [isConfig, setIsConfig] = useState(false)
  const {zoom, setZoom, currTap, getButtons,
    setLogs, setCurrentDeviceID, currentDeviceID, getLogs } = useHomeContext()
  const { getProfile } = useAuthContext()
  const {handleConnectEvent} = SocketClient()

  const tap: string = useMemo(() => currTap || localStorage.getItem('tap') || TAB_TYPE_ENUM.HOME, [localStorage.getItem('tap'), currTap])

  
  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token || !token?.length) {
      return history.push('/login')
    }

    getProfile(token).then((data: any) => {
      if (!data) {
        history.push('/login')
        localStorage.removeItem('token');
        notification.error({
          message: 'Get thông tin user sai!',
          placement: 'topRight',
        })
      }
      if (data?.currentDevice) {
        setCurrentDeviceID(data?.currentDevice)
        getLogs(data?.currentDevice).then((data: any) => {
              if (!data?.logs?.length) return
              setLogs([...data.logs])
          })
      }

      handleConnectEvent()
      })
      .catch((_error: any) => {
        history.push('/login')
        localStorage.removeItem('token');
        notification.error({
          message: 'Get thông tin user sai!',
          placement: 'topRight',
        })
      })

  }, [localStorage.getItem('token')])


  const onZoom = useCallback(() => {
    // const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    // const ratioWidth = width / 480;
    const ratioHeight = height / 768;
    // const zoomNumber = ratioWidth > ratioHeight ? ratioHeight : ratioWidth;

    setZoom(ratioHeight)
}, [])

useEffect(() => {
  onZoom();
}, [onZoom])

const contentComponent: {[key: string]: any} = {
  HOME: <Home/>,
  TRAM: <Tram />,
  THIETBI: <ThietBi />
}

useEffect(() => {
    if (!currentDeviceID) return
    getButtons({ deviceID: currentDeviceID, tab: currTap})
}, [currentDeviceID, currTap])

  return (
    <Box className="box__layout"  style={{
      zoom,
      margin: 'auto'
    }}>
      <Box variant='box_layout_container' id='box__layout'>
        <Header setIsConfig={setIsConfig} />
        {contentComponent[tap]}
      </Box>
      <Footer />
      <Modal onCancel={() => setIsConfig(false)}  open={isConfig} footer={<></>} >
          <SystemConfig onCancel={() => setIsConfig(false)} />
      </Modal>
    </Box>
  )
}

export default React.memo(System)
