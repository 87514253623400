export const text = {
    text__form_valid_errors: {
        color: '#c23533',
        textAlign: 'left',
        margin: '8px 0',
        fontStyle: 'normal',
        fontSize: '14px',
    },
    text__login_logo: {
        display: 'block',
        textAlign: 'center',
        fontWeight: 700,
        fontSize: '40px',
        textTransform: 'lowercase',
        lineHeight: '34px',
        color: 'rgba(0,159,127,1)',
        letterSpacing: '3px',
    },
    text__login_sub_title: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        marginTop: '1.25rem',
        marginBottom: '2.5rem',
        color: 'rgba(107,114,128,1)',
    },
    text__system_title: {
        height: '120px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '48px',
        fontWeight: 600,
        color: '#1b978c',
        lineHeight: '32px',
    },
    text__system_log_title: {
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '32px',
        fontWeight: 600,
        color: '#2ba398',
        lineHeight: '32px',
        fontFamily: 'monospace',
        height: '100px',
    },
    text__header_account_name: {
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 510,
        color: '#1a2144',
    },
    text__header_account_des: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        color: '#91979b',
    },
    text__header_system_item_label: {
        fontSize: '12px',
        fontWeight: 400,
        color: '#91979b',
    },
    text__header_system_item_value: {
        fontSize: '26px',
        fontWeight: 500,
        color: '#1a2144',
    },

    text__device_title: {
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#1a2144',
        width: '100%',
        whiteSpace: 'break-spaces',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },

    text__device_des: {
        fontSize: '12px',
        fontWeight: 400,
        color: '#939393',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    text__system_config_title: {
        fontSize: '24px',
        fontWeight: 500,
        color: '#686b75',
    },
}