import { useEffect, useState } from "react"
import { SocketClient } from "../../../../../socket/socketIo"
import './style.css'

const ConnectStatus = () => {
    const [isPing, setIsPing] = useState(false)
    const {handlePingEvent} = SocketClient()
    useEffect(() => {
        if (!localStorage.getItem("token") ) return
        handlePingEvent(setIsPing)

        return () => {
            setIsPing(false)
        }
    }, [])

    useEffect(() => {
        if (!isPing) return

        const timeout = setTimeout(() => {
            setIsPing(false)
        }, 1000)

        return () => {
            clearTimeout(timeout)
        }
    }, [isPing])

    return (
        <div className={isPing ? 'header-left-ping-active' : 'header-left-ping' }/>
    )
}

export default ConnectStatus