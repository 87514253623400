interface IGetFormErrorParams {
  touched?: any,
  error?: any,
}

export const getFormErrorMessage = ({touched, error}: IGetFormErrorParams) => {
  if (!touched || !error) {
    return
  }

  return error
}
