import { Box, Button, Text } from 'rebass'
import * as yup from 'yup'
import { useFormik } from 'formik'
import Form from '../../../components/form/form'
import { Input } from '@rebass/forms'
import { useState } from 'react'
import eye from '../../../assets/eye.svg'
import eye_slash from '../../../assets/eye_slash.svg'
import FieldHOC from '../../../components/form/input'
import { getFormErrorMessage } from '../../../helpers/getFormErrorMessage'
import { phoneRegExp } from '../../../constant'

const signupFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('error-email-format')
    .required('error-email-required'),
  phone: yup.string().optional().min(10).max(10).matches(phoneRegExp, 'Phone is no valid'),
  password: yup.string().required('error-password-required'),
  confirm_password: yup.string().required('error-password-required'),
})

const SignupForm: React.FC = () => {
  const [openPass, setOpenPass] = useState(false)
  const [openConfirmPass, setOpenConfirmPass] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: signupFormSchema,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2))
    }
  })


  return (
    <Box variant='box__container'>
    <Text textAlign='center' variant='text__login_sub_title' fontSize={3}>Sign up with your email & password</Text>
    <Form
      key='form-use-fomik'
      handleSubmit={formik.handleSubmit}
      handleReset={formik.handleReset}
    >
      <Box variant='box__sign_form'>
        <FieldHOC
          name='email'
          id='email'
          errorMessage={getFormErrorMessage({ error: formik?.errors?.email, touched: formik?.touched?.email })}
          errorVariant='text__form_valid_errors'
          labelVariant='label__form_input'
          label='Email'
        >
          <Input
            name='email'
            placeholder=''
            type='text'
            value={formik?.values?.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            variant='input__form_info' />
        </FieldHOC>
        <FieldHOC
          name='phone'
          id='phone'
          errorMessage={getFormErrorMessage({ error: formik?.errors?.phone, touched: formik?.touched?.phone })}
          errorVariant='text__form_valid_errors'
          labelVariant='label__form_input'
          label='Phone'
        >
          <Input
            name='phone'
            placeholder=''
            type='text'
            value={formik?.values?.phone}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            variant='input__form_info' />
        </FieldHOC>
        <FieldHOC
          name='password'
          id='password'
          errorMessage={getFormErrorMessage({ error: formik?.errors?.password, touched: formik?.touched?.password })}
          errorVariant='text__form_valid_errors'
          labelVariant='label__form_input'
          label='Password'
          fieldVariant='box__form_password'
          contentVariant='box__form_password_content'
          topVariant='box__form_top'
          trailingVariant='box__form_trailing'
          trailing={
            <Button
              bg='transparent !important'
              variant='ghost'
              type='button'
              onClick={(e) => {
                e.stopPropagation()
                setOpenPass(!openPass)
              }}
              sx={{
                margin: 0,
                minWidth: 0,
                width: '3rem',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& button': {
                  padding: 0,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }
              }}
            >
              {!openPass ? <img src={eye_slash} width='25' height='20' />
                : <img src={eye} width='25' height='20' />}
            </Button>
          }
        >
          <Input
            name='password'
            placeholder=''
            type={openPass ? 'text' : 'password'}
            value={formik?.values?.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            variant='input__form_password' />
        </FieldHOC>
        <FieldHOC
          name='confirm_password'
          id='confirm_password'
          errorMessage={getFormErrorMessage({ error: formik?.errors?.confirm_password, touched: formik?.touched?.confirm_password })}
          errorVariant='text__form_valid_errors'
          labelVariant='label__form_input'
          label='Confirm password'
          fieldVariant='box__form_password'
          contentVariant='box__form_password_content'
          trailingVariant='box__form_trailing'
          topVariant='box__form_top'
          trailing={
            <Button
              bg='transparent !important'
              variant='ghost'
              type='button'
              onClick={(e) => {
                e.stopPropagation()
                setOpenConfirmPass(!openConfirmPass)
              }}
              sx={{
                margin: 0,
                minWidth: 0,
                width: '3rem',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& button': {
                  padding: 0,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }
              }}
            >
              {!openConfirmPass ? <img src={eye_slash} width='25' height='20' />
                : <img src={eye} width='25' height='20' />}
            </Button>
          }
        >
          <Input
            name='confirm_password'
            placeholder=''
            type={openConfirmPass ? 'text' : 'password'}
            value={formik?.values?.confirm_password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            variant='input__form_password' />
        </FieldHOC>
        <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}>
        <Button
          key='form-btn-submit'
          type='submit'
          my={2}
          width={80}
          variant='button__form_submit'
        >
          Login
        </Button>
        </Box>
      </Box>
    </Form>
  </Box>
  )
}

export default SignupForm
