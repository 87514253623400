import { Box, Text } from "rebass"
import SignupForm from "./ui/signup-form"

const Signup = () => {
  return (
    <Box variant='box__login'>
      <Box variant='box__login_content'>
        <Text variant='text__login_logo'>Cellphone</Text>
        <SignupForm />
      </Box>
    </Box>
  )
}

export default Signup
