import { Button, Col, Modal } from "antd"
import { useCallback, useEffect, useState } from "react"
import { AiOutlinePlus } from "react-icons/ai"
import useHomeContext from "../../../../logic/providers"
import ScheduleForm from "./scheduleForm/scheduleForm"
import ScheduleItem from "./scheduleItem"

interface IProps {
    item: any
}

const DeviceSchedule = ({item}: IProps) => {
    const {schedules, getShedules, setSchedules, setCurrSchedule} = useHomeContext()
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        getShedules(item?.buttonID || '').then((data) => {
            setSchedules(data)
        })
    }, [])

    const ScheduleList = useCallback(() => {
        if (!schedules?.length) {
            return (
                <div className="schedule-list__empty">Chưa có lịch hẹn!</div>
            )
        }

        return (
            <div className="schedule-list__data">
                {schedules?.map((val: any, idx: number) => {
                    return (
                        <div key={val?.scheduleDevicesID || idx}>
                            <ScheduleItem
                                item={val}
                                key={idx}
                                onOpenAddModal={() => {
                                    setCurrSchedule(val)
                                    setOpenModal(true)
                                }}
                            />
                        </div>
                    )
                })}
            </div>
        )
    }, [schedules])

    const onCancel = () => {
        setOpenModal(false)
        setCurrSchedule(null)
    }

    return (
        <Col className="device-shedules">
            <div className="device-shedules__title">
                <h2>
                    Tất cả các thời gian hẹn
                </h2>
            </div>
            <div className="device-shedules__add">
                <Button
                    onClick={() => {
                        setCurrSchedule(null)
                        setOpenModal(true)
                    }}
                >
                    <AiOutlinePlus />
                </Button>
            </div>
            <div className="device-shedules__list">
                <ScheduleList />
            </div>
            <Modal onCancel={onCancel} open={openModal} footer={null} >
                <ScheduleForm buttonData={item} onCancel={onCancel}  />
            </Modal>
        </Col>
    )
}

export default DeviceSchedule
