import { Layout } from './components/layout/layout'
import { ThemeProvider } from 'emotion-theming'
import { theme } from './theme'
import { RouteHoC } from './HOC/route_hoc'
import { routes } from './routes'
import { ErrorPage } from './modules/error/index.page'
import { providers } from './providers'
import { useLocation } from 'react-router-dom'
import {useEffect, useMemo } from 'react'
import { Box } from 'rebass'
import './App.css'
import "antd/dist/antd.css";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  const location = useLocation()
  const curPathName = location?.pathname.replace('/', '')
  const publidRoutes = ['login', 'register']
  const isPagePublic = useMemo(() => {
    return !!publidRoutes.includes(curPathName)
  }, [curPathName])


  if (isPagePublic) {
    return (
      <ThemeProvider theme={theme}>
      <div className='App'>
        <Box className='layout_public'>
          <RouteHoC routes={routes} ErrorPage={ErrorPage} providers={providers} />
        </Box>
      </div>
    </ThemeProvider>
    )
  }



  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <Layout>
          <RouteHoC routes={routes} ErrorPage={ErrorPage} providers={providers} />
        </Layout>
      </div>
    </ThemeProvider>
  )
}

export default App
