import { useCallback } from 'react'
import { IProviderHocProps } from '../types'

export const ProviderHoC: React.FC<IProviderHocProps> = ({ children, providers }) => {

  const ProviderComponent = useCallback(() => {
    return (
      <>
        {providers.reduce((Prev, Current: React.FC<{ children: JSX.Element}>) => {
          return (
            <Current>
              {Prev}
            </Current>
          )
        }, <>{children}</>)}
      </>
    )
  }, [children])

  return <ProviderComponent />
}
