import { Box } from "rebass"
import './style.css'

interface IProps {
    icon?: any;
    onClick: (e: any) => void;
    status?: boolean
}

export const DeviceButton = ({ icon, onClick, status = false }: IProps) => {
    return (
        <Box className='nav' variant='box__device_button'>
            <li
                style={{
                    zoom: 0.7
                }}
                className={status ? 'box__device_button_active' : 'box__device_button_inactive'}
            >
                <button onClick={onClick}>
                    {icon || <span className="icon-home"></span>}
                </button>
            </li>
        </Box >
    )
}
