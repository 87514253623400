export const radio = {
  radio__form: {
    transition: '0.2s all linear',
    marginRight: '5px',
    position: 'relative',
    p: 0,
    flexWrap: 'wrap',
    input: {
        maxWidth: '20px',
        height: '20px',
        borderRadius: '50%',
        border: '6px solid #ccc',
    },
    '&:checked': {
        border: '6px solid blue'
    }
  },
}