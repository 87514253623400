// import { IHomeContextProps } from '../../types'
import useHomeContext from '../home/logic/providers'

export const ErrorPage = () => {
  // style Provider
  // const { title }: IHomeContextProps = useHomeContext()

  return (
    <div>
      404
      {/* <p>{title}</p> */}
    </div>
  )
}
