export const select = {
    select__form: {
        boxSizing: 'border-box',
        margin: 0,
        minWidth: 0,
        display: 'block',
        width: '100%',
        appearance: 'none',
        fontSize: '16px',
        lineHeight: 'inherit',
        background: 'rgb(232, 240, 254)',
        padding: '8px 0 8px 20px',
        border: '1px solid rgba(0,0,0,0.12)',
        boxShadow: '1px 1px 1px 1px #f3f5fa',
        borderRadius: '10px',
        color: '#3e4450',
        height: '50px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '&:placeholder': {
            color: '#7d8db3',
            verticalAlign: 'bottom',
        },
        '&:focus-visible': {
            border: '0.5px solid red',
            outline: 'none',
        }
    },
    slelect__devices: {
        backgroundColor: 'transparent',
        boxShadow: 'rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px',
        height: '50px',
        fontSize: '18px',
        color: '#65678a',
        whiteSpace: 'break-spaces',
        borderColor: '#91c4ff',
        borderRadius: '10px',

        '&:focus-visible': {
            outline: 0
        }
    }
}