export const phoneRegExp = /^[0-9]{10}$/

export const host = 'https://agri.hqn.vn/api'
export const socket_host = 'https://agri.hqn.vn'
export const token = window.localStorage.getItem('token') || ""

export const TAB_TYPE_ENUM = {
    HOME: 'HOME',
    THIETBI: 'THIETBI',
    TRAM: 'TRAM'
}

export const events = ['START', 'STOP', 'SEND']

export const SOCKET_EVENT = {
    CONNECT: "connect",
    CONNECT_ERROR: "connect_error",
    DEVICE_INFO: "DEVICE_INFO",
    LOG: "LOG",
    WEB_LOG: "WEB_LOG",
    START: "START",
    STOP: "STOP",
    SEND: "SEND",
    UPDATE_CURRENT_DEVICE: "UPDATE_CURRENT_DEVICE",
    PING: "PING"
}

export const interval_default = [1, 2, 3, 4, 5, 6, 0]

export const MODAL_TYPE = {
    CREATE: "CREATE",
    UPDATE: "UPDATE"
}

export const hour_format = "HH:mm"