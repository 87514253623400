import { Box } from "rebass"
import DeviceDetail from "./Modal/deviceDetail"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { MdAdd } from "react-icons/md";
import { DeviceButton } from "./deviceButton";
import { Modal, notification } from "antd";
import useHomeContext from "../../logic/providers";


const DeviceItemAdd = () => {
    const [isShow, setIsShow] = useState(false)
    const { createButton, currTap, currentDeviceID } = useHomeContext()
    const ref = useRef<HTMLDivElement>()

    const onCancel = () => {
        setIsShow(false)
    }

    const onOK = (data: any) => {
        setIsShow(false)

        const body: {[key: string]: any} = {
            deviceID: currentDeviceID || '',
            buttonName: data?.buttonName || '',
            // icon: data?.icon || null,
            valueOn: data?.valueOn,
            valueOff: data?.valueOff,
            eventName: data?.eventName || 'SEND',
            tab: currTap,
        }

        createButton({...body}).then((res: any) => {
            if (!!res?.data?.result) {
                notification.info({
                    message: `Thêm nút thành công!`,
                    placement: 'topRight',
                  });
            }
        }).catch((error: any) => {
            if (error.response) {
                notification.error({
                    message: `${error.response.data?.error}`,
                    placement: 'topRight',
                });
              }
        })
    }

    const onResize = useCallback(() => {
        const width = document.getElementById('box__layout')?.getBoundingClientRect().width || 480;
        const containerWith = width <= 480 ? width : 480
        const btnWidth = containerWith / 2 - 15

        if (ref.current) {
            ref.current.style.width = `${btnWidth}px`
        }
    }, [])

    useLayoutEffect(() => {
        onResize();
        //   window.addEventListener('orientationchange', onResize);
        //   window.addEventListener('resize', onResize);

        //   return () => {
        //     window.removeEventListener('orientationchange', onResize);
        //     window.removeEventListener('resize', onResize);
        //   }
      }, [onResize, ref.current])

    return (
        <Box variant='box__device_add' ref={ref} >
            <Box className='box__device_action' onClick={() => setIsShow(true)}>
                <DeviceButton onClick={() => setIsShow(true)} icon={<MdAdd color='#727272'style={{ fontSize: '28px'}} />}/>
            </Box>
            <Modal onCancel={onCancel} open={isShow} footer={null}>
                <DeviceDetail onOk={onOK} onCancel={onCancel} />
            </Modal>
        </Box>
    )
}

export default DeviceItemAdd