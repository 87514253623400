import {useMemo } from 'react';
import { io, Socket } from 'socket.io-client'
import { SOCKET_EVENT, socket_host } from '../constant';

export const SocketClient = () => {
  const socketIO: Socket | null = useMemo(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      // throw new Error("bearerToken not found");

      return null
    }

   const socket = io(socket_host, {
      extraHeaders: {
        "Authorization": `Bearer ${token}`
      },
      path: `/api/socket.io`
    });

    return socket
  }, [localStorage.getItem("token")])

  const handleConnectEvent = () => {

   socketIO?.on(SOCKET_EVENT.CONNECT, () => {
    //   console.log('connect success')
    });
  }

  const handleConnectErrorEvent = () => {

   socketIO?.on(SOCKET_EVENT.CONNECT_ERROR, () => {
    //   console.log('connect error')
    });
  }

  const handlePingEvent = (setIsPingActive: any) => {
    socketIO?.on(SOCKET_EVENT.PING, () => {
      setIsPingActive(true)
    });
  }

 const handleLogsEvent = (setLogs: any) => {
    socketIO?.on(SOCKET_EVENT.LOG, (args: any) => {

        if (!args || !Object.keys(args)?.length) return
  
        setLogs((prevState: any[], _props: any) =>  {
          const newTemp = {
            content: {
              message: Object.values(args)?.[0] || '',
               name: Object.keys(args)?.[0] || ''
            },
            createdAt: new Date(),
          }
  
  
          socketIO.emit(SOCKET_EVENT.WEB_LOG, newTemp)
          if (!prevState || !prevState?.length) { 
            return [newTemp]
          }
  
          const temps = [newTemp, ...prevState]
          // temps.push(newTemp)
  
          return temps
        })
  
      });
  }

  const handleDeviceInfoEvent = (setDeviceInfo: any) => {
    socketIO?.on(SOCKET_EVENT.DEVICE_INFO, (args: any) => {
        const info = {
          ample: args?.A || '0',
          h: args?.Hz || '0',
          hz: args?.H || '0',
          t: args?.T || '0',
          vol: args?.V || '0',
          watt: args?.W || '0',
        }
  
        setDeviceInfo(info)
      });
  }

  return {
    socketIO: socketIO,
    handleConnectEvent,
    handleConnectErrorEvent,
    handlePingEvent,
    handleLogsEvent,
    handleDeviceInfoEvent
  }
}