import { Switch } from "antd"
import { interval_default } from "../../../../../../../constant"
import './style.css'

interface IProps {
    item: any;
    onOpenAddModal: () => void;
}

const ScheduleItem = ({item, onOpenAddModal}: IProps) => {
    return (
        <div className="schedule-item" onClick={onOpenAddModal}>
            <div className="schedule-item__time">
                <h3>{item?.timeStart}</h3>
                <h3>{item?.timeEnd}</h3>
            </div>
            <div className="schedule-item__interval">
                {interval_default.map((value) => {
                    const dayActive = !!item?.interval?.includes(value) && "active"
                    const tempVal = value === 0 ? 'c' : value + 1

                    return (
                        <span className={`item__interval__day ${dayActive}`} key={value}>{tempVal}</span>
                    )
                })}
            </div>
            {/* <div className="schedule-item__action">
                <Switch onChange={onOpen} />
            </div> */}
        </div>
    )
}

export default ScheduleItem
