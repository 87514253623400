import axios from "axios"
import { host, token } from "../../../constant"


export const onLogin = async (body: any) => {
    try {
        const res = await axios.post(
          `${host}/user/signin`, body)
    
        return {
          data: res?.data,
          status: res?.status,
          statusText: res?.statusText,
        }
      } catch (error) {
        throw error
      }
}


export const onGetProfile = async (tokenValue: string) => {
  try {
    const res = await axios.get(
      `${host}/user/me`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenValue}`,
      },
    })

      return {
        data: res?.data,
        status: res?.status,
        statusText: res?.statusText,
      }
    } catch (error) {
      throw error
    }
} 
