export const box = {
  box__container: {
    width: ['100%', '480px', '480px'],
    padding: ['2rem', '10px', '10px'],
    margin: 'auto'
  },
  box__form_info: {
    width: '100%',
    lineHeight: 2.5,
  },
  box__form_content: {
    display: 'flex',
    alignItems: 'center',
  },
  box__form_password: {
    variant: 'variants.box__form_info',
    position: 'relative'
  },
  box__login: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  box__login_content: {
    width: '100%',
  },
  box__form_top: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box__form_password_content: {
    display: 'flex',
    alignItems: 'stretch',
    borderWidth: '1px',
    borderRadius: '5px',
    border: '1px solid rgba(209,213,219,1)',
    height: '3rem'
  },
  box__form_trailing: {
    height: '3rem',
    display: 'flex',
    alignItems: 'center',

    'button': {
      padding: '0'
    }
  },
  box__sign_form: {
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
  },
  box__client_system: {
    maxWidth: '768px',
    margin: 'auto',
    height: ',',
    overflowY: 'hidden',
    background: '#fff',
    position: 'relative',
  },
  box__system_tabs: {
    display: 'flex',
    color: '#199d91',
  },
  box__tap_item: {
    fontSize: '18px',
    padding: '14px 30px',
    fontWeight: 400,
    cursor: 'pointer',
    width: '50%',
    textAlign: 'center',
    borderBottom: '1px solid #baebe6',
    borderRadius: '10px 10px 0 0'
  },
  box__tap_item_active: {
    variant: 'variants.box__tap_item',
    borderBottom: '1px solid #baebe6',
    fontWeight: 600,
    background: '#baebe6',
  },
  box__system_content: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    gap: '30px',
    height: 'calc(100% - 178px)',
  },
  box__menu_actions: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '10px 20px',
  },
  box__menu_actions_left: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',

  },
  box__system_report: {
    display: 'flex',
    height: '150px',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
    background: '#fff',
    margin: '20px 0',
  },
  box__system_report_item: {
    width: 'calc(100% / 4)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '32px',
    fontWeight: 400,
    height: '100%',
    gap: '10px',
    borderRight: '1px solid #ccc',

    '&:last-child': {
      borderRight: 0,
    }
  },
  box__system_no_logs: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 500,
    color: '#9f9f9f',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box__system_devices: {
    display: 'grid',
    height: 'fit-content',
    gap: '20px',
    gridTemplateAreas: '"a a a"',
    padding: '40px 20px',
  },
  box__device_item: {
    textAlign: 'center',
    fontSize: '18px',
    padding: '10px 0',
    border: '1px solid #dfdfdf',
    borderRadius: '15px',
    color: '#9f9f9f',
    background: '#dfdfdf',
    cursor: 'pointer',
  },
  box__device_item_active: {
    variant: 'variants.box__device_item',
    color: '#477a49',
    background: '#4dd99a',
    border: '1px solid #3bc954',
  },
  box__system_logs_data: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #ccc',
    maxHeight: 'calc(100% - 100px)',
    overflowY: 'auto',
  },
  box__system_log_item: {
    borderTop: '1px solid #ccc',
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0 20px 20px',
    gap: '10px',
    color: '#9f9f9f',
    fontSize: '20px',
  },
  box__system_setting: {
    position: 'absolute',
    bottom: '30px',
    right: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '50%',
    cursor: 'pointer',
    boxShadow: 'rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px',
  },
  box__modal: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgb(0 0 0 / 40%)',
    top: 0,
    left: 0,
    zIndex: 999,
  },
  box__modal_container: {
    position: 'absolute',
    background: '#fff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '375px',
    minHeight: 'fit-content',
  },
  box_layout_container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: '480px',
    background: '#fff',
    gap: '3px',
    position: 'relative',
    height: '100%',
    overflowY: 'auto !important',
    width: '100%',

    // '&::-webkit-scrollbar': {
    //   width: 0, 
    //   height: 0,
    // },
  },
  box__header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '20px 0 11px',
    padding: '0 10px',
    width: '100%'
  },
  box__header_account: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: '8px',
    height: '100%',
  },
  box__header_system: {
    gap: '10px 15%',
    alignItems: 'center',
    background: '#f5fcff',
    height: '110px',
    justifyContent: 'center',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    padding: '10px 20px',
    margin: '0 10px',
    boxShadow: 'rgb(0 0 0 / 2%) 0px 1px 3px 0px, rgb(27 31 35 / 15%) 0px 0px 0px 1px',
    borderRadius: '10px',
  },
  box__header_system_item: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '14px',
  },
  box__header_system_item_left: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '3px',
    width: '25px',
  },

  box__header_system_item_right: {

  },

  box__system_logs_empty: {
    variant: 'variants.box__system_logs',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '175px',
  },

  box__system_logs: {
    display: 'flex',
    width: 'calc(100% - 20px)',
    background: '#f5fcff',
    margin: '10px auto 0',
    borderRadius: '14px',
    boxShadow: 'rgb(204 219 232) 3px 3px 6px 0px inset, rgb(255 255 255 / 50%) -3px -3px 6px 1px inset',
    position: 'relative',

    'ul': {
      padding: '12px 0 10px 16px',
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
      margin: '10px 0',
      minHeight: '175px',
      maxHeight: '175px',
      overflowY: 'auto',
      width: '100%',
      alignItems: 'flex-start',

      // '&::-webkit-scrollbar': {
      //   width: '1px',
      //   background: '#ccc',
      // },

      'li': {
        fontSize: '13px',
        color: '#939393',
        padding: '0',
      }
    }
  },
  box__footer: {
    display: 'flex',
    // position: 'absolute',
    bottom: 0,
    background: '#f3f3f3',
    height: '65px',
    alignItems: 'center',
    maxWidth: '480px',
    padding: '0 20px',
    justifyContent: 'space-between',
    margin: 'auto',
    width: '100%',
  },

  box__footer_center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },


  box__device_content: {
    gap: '10px',
    padding: '0 10px',
    margin: '16px 0',
  },

  box__device: {
    display: 'flex',
    height: '85px',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '4px',
    borderRadius: '16px',
    position: 'relative',
    background: '#42ddee36',
    padding: '0 10px',
  },

  box__device_add: {
    variant: 'variants.box__device',
    background: '#ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '240px',

    '.box__device_action': {
      position: 'relative',
      top: 0,
      left: 0,

      'button': {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',

        'svg': {
          fontSize: '38px',
        }
      }
    }
  },

  box__device_1: {
    variant: 'variants.box__device',
    background: '#42ddee36',
  },

  box__device_2: {
    variant: 'variants.box__device',
    background: '#ff52b53b',
  },

  box__device_3: {
    variant: 'variants.box__device',
    background: '#fd41411f',
  },

  box__device_4: {
    variant: 'variants.box__device',
    background: '#0007fd1f',
  },

  box__device_right: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    alignItems: 'center',
  },

  box__device_button: {
    minWidth: '60px',
    minHeight: '60px',
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },

  box__device_left: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    padding: 0,
    marginTop: '10px',
    alignItem: 'start',
    textAlign: 'left',
    flexGrow: 1,
  },

  box__device_action: {
    position: 'absolute',
    top: '5px',
    left: '10px',
  },

  box__devices: {
    display: 'block',
    overflowX: 'hidden',
    overflowY: 'auto',
    whiteSpace: 'nowrap',
    // height: 'calc(100% - 175px)',

    '&::-webkit-scrollbar': {
      width: 0, 
      height: 0,
    },
  },

  box_item: {
    display: 'inline-block',
    width: '100%'
  },

  box__bottom_content: {
    textAlign: 'center',
    fontSize: '20px',
    letterSpacing: '24px',
  },
  box__system_config: {
    // marginTop: '5%',
    // padding: '0 16px',
  },
  box__system_config_content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,minmax(50px,2fr))',
    gridTemplateRows: '1fr',
    gap: '20px',
  },
  box__system_config_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    // marginBottom: '20px',
  },
  box__system_config_footer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  box__devices_container: {
    variant: 'variants.box__system_config_container',
    // paddingTop: '10px',
    textAlign: 'left',

  },
  box__form_schedule: {
    width: 'calc(50% - 10px)'
  }
}
