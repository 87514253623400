import Form from "../../../../../../../components/form/form"
import FieldHOC from "../../../../../../../components/form/input"
import DatePicker from "react-datepicker"
import { getFormErrorMessage } from "../../../../../../../helpers/getFormErrorMessage"
import { useFormik } from "formik"
import * as yup from 'yup'
import { hour_format, interval_default } from "../../../../../../../constant"
import { Button, Modal, notification, TimePicker } from "antd"
import './style.css'
import moment from "moment"
import { Box } from "rebass"
import useHomeContext from "../../../../../logic/providers"
import { useEffect, useMemo } from "react"

const scheduleFormSchema = yup.object().shape({
    timeStart: yup
      .string()
      .nullable()
      .required('Thời gian bắt đầu là bắt buộc!'),
    timeEnd: yup
      .string()
      .nullable()
      .required('Thời gian kết thúc là bắt buộc!'),
    //   .test('isValid', 'Giờ kết thúc không thể trước giờ bắt đầu', (value, testContext) => {
    //         return moment(value, 'HH:mm').isSameOrAfter(moment(testContext.parent.timeStart, 'HH:mm').add(1, 'hours'))
    //     }),
    interval: yup.array().of(yup.string()),
  })

interface IProps {
    onCancel: () => void;
    buttonData?:any;
}

const ScheduleForm = ({buttonData, onCancel}: IProps) => {
    const {currSchedule, createSchedule, updateSchedule, deleteSchedule, setCurrSchedule} = useHomeContext()
    const formik = useFormik<any>({
        validationSchema: scheduleFormSchema,
        initialValues: {
            timeStart: currSchedule?.timeStart || '00:00',
            timeEnd: currSchedule?.timeEnd || '00:00',
            interval: currSchedule?.interval || []
          },
        onSubmit: values => {
          if (currSchedule?.scheduleDevicesID) {
            updateSchedule({
                scheduleID: currSchedule?.scheduleDevicesID,
                data: values
            }).then((_data: any) => {
                onCancel()
                notification.success({ message: 'Cập nhật thành công :)'})
            }).catch((_error: any) => {
                notification.error({message: 'Cập nhật thất bại :('})
            })

            return
          }

          const bodyData = {
            ...values,
            buttonID: buttonData?.buttonID
          }

          createSchedule({ data: bodyData}).then((_data: any) => {
            onCancel()
            notification.success({ message: 'Tạo mới thành công :)'})
        }).catch((_error: any) => {
            notification.error({message: 'Tạo mới thất bại :('})
        })

        }
      })

    useEffect(() => {
        formik.setValues({
            timeStart: currSchedule?.timeStart || '00:00',
            timeEnd: currSchedule?.timeEnd || '00:00',
            interval: currSchedule?.interval || []
        })
    }, [currSchedule])

    const onChange = (e: any, inputName: any) => {
        formik.setValues({
            ...formik.values,
            [inputName]: e ? moment(e).format("HH:mm") : e
        })
    }

    const onIntervalsChange = (val: any) => {
    let temInterval = formik.values.interval || []

    if (!temInterval || !temInterval?.length) {
        
        formik.setValues({
            ...formik.values,
            interval: [val]
        })

        return
    }

    if (temInterval?.includes(val)) {
        temInterval = temInterval.filter((item:any) => item !== val)

        formik.setValues({
            ...formik.values,
            interval: temInterval
        })

        return
        }

    formik.setValues({
        ...formik.values,
        interval: [...temInterval, val]
    })
    }

    const onDeleted = () => {
    Modal.confirm({
        title: "Xác nhận",
        content: "Bạn có chắc muốn xóa?",
        onOk: () => {
            deleteSchedule(currSchedule?.scheduleDevicesID || '').then((data: any) => {
                if (!data) {
                    notification.error({message: 'Xóa thất bại :('})
                    return
                }
                onCancel()
                notification.success({ message: 'Xóa thành công :)'})
            }).catch((error: any) => {
                notification.error({message: 'Xóa thất bại :('})
            })

        }
    })
    }

    return (
        <Form handleSubmit={formik.handleSubmit} >
            <div className="schedule-form">
                <div className="schedule-form-title">Tạo lịch hẹn</div>
                <div className="schedule-form-content">
                    <FieldHOC
                        name='timeStart'
                        id='timeStart'
                        errorMessage={getFormErrorMessage({ error: formik?.errors?.timeStart, touched: formik?.touched?.timeStart })}
                        errorVariant='text__form_valid_errors'
                        labelVariant='label__form_input'
                        label='Thời gian bắt đầu'
                        fieldVariant="box__form_schedule"
                    >
                        <TimePicker
                            onChange={(e) => onChange(e, 'timeStart')}
                            value={formik.values?.timeStart ? moment(formik.values?.timeStart, hour_format): null}
                            format={hour_format}
                        />
                    </FieldHOC>
                    <FieldHOC
                        name='timeEnd'
                        id='timeEnd'
                        errorMessage={getFormErrorMessage({ error: formik?.errors?.timeEnd, touched: formik?.touched?.timeEnd })}
                        errorVariant='text__form_valid_errors'
                        labelVariant='label__form_input'
                        label='Thời gian kết thúc'
                        fieldVariant="box__form_schedule"
                    >
                        <TimePicker
                            onChange={(e) => onChange(e, 'timeEnd')}
                            value={formik.values?.timeEnd ? moment(formik.values?.timeEnd, hour_format) : null}
                            format={hour_format}
                        />
                    </FieldHOC>
                    <div className="inteval">
                        <div className="time-now">
                            <span>Các thứ trong tuần</span>
                            {/* <span>{moment().locale('vi').format("dddd, MMMM Do YYYY, h:mm:ss a")}</span> */}
                        </div>
                        <div className="field-interval">
                            {interval_default.map((item: any, idx: number) => {
                                const isActive = !!formik.values.interval?.length && formik.values.interval?.includes(item)
                                const value = item === 0 ? 'c' : item + 1

                                return (
                                    <Button 
                                        key={idx}
                                        className={isActive ? 'field-interval-btn-active' : 'field-interval-btn'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            onIntervalsChange(item)
                                        }}
                                    >
                                        {value}
                                    </Button>
                                )
                            })}
                        </div>
                    </div>
                    
                </div>
                <Box variant='box__device_item_footer' className="box___device_item_footer">
                        <Button className="btn__system_config_reset" onClick={(e: any) => {
                            onCancel()
                            formik.handleReset(e)
                        }}>Hủy</Button>

                        <div className="btn_system_button_control_right">
                            {currSchedule?.scheduleDevicesID ? <Button className="btn__system_config_delete" onClick={(e: any) => {
                                e.preventDefault()
                                onDeleted()
                            }}>Xóa</Button> : null}
                            <Button
                                className="btn__system_config_save"
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    formik.handleSubmit()
                                    e.stopPropagation()
                                }}
                            >
                                Lưu
                            </Button>
                        </div>
                </Box>
            </div>
        </Form>
    )
}

export default ScheduleForm
