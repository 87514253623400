import { Box, Button, Text } from 'rebass'
import * as yup from 'yup'
import { useFormik } from 'formik'
import Form from '../../../components/form/form'
import { Input } from '@rebass/forms'
import { useState } from 'react'
import FieldHOC from '../../../components/form/input'
import { getFormErrorMessage } from '../../../helpers/getFormErrorMessage'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import useAuthContext from '../logic/providers'
import { useHistory } from 'react-router-dom'
import { Modal } from 'antd'

const loginFormSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .max(11)
    .min(10)
    .required('error-phoneNumber-required'),
  password: yup.string().required('error-password-required'),
});

const LoginForm: React.FC = () => {
  const [open, setOpen] = useState(false)
  const {login} = useAuthContext()
  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      password: ''
    },
    validationSchema: loginFormSchema,
    onSubmit: (values: any) => {
      login(values).then((data: any) => {
        if (data) {
          return history.push('/')
        }
      }).catch((_error: any) => {
        Modal.error({
          title: 'Lỗi đăng nhập',
          content: 'Số điện thoại hoặc mật khẩu chưa đúng !',
          okText: 'OK'
        })
      })
    },
  });


  return (
    <Box variant='box__container'>
    <Text textAlign='center' variant='text__login_sub_title' fontSize={3}>Đăng nhập với số điện thoại</Text>
    <Form
      key='form-use-fomik'
      handleSubmit={formik.handleSubmit}
      handleReset={formik.handleReset}
    >
      <Box variant='box__sign_form'>
        <FieldHOC
          name='phoneNumber'
          id='phoneNumber'
          errorMessage={getFormErrorMessage({ error: formik?.errors?.phoneNumber, touched: formik?.touched?.phoneNumber })}
          errorVariant='text__form_valid_errors'
          labelVariant='label__form_input'
          label='Số điện thoại'
        >
          <Input
            name='phoneNumber'
            placeholder=''
            type='text'
            value={formik?.values?.phoneNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            variant='input__form_info' />
        </FieldHOC>
        <FieldHOC
          name='password'
          id='password'
          errorMessage={getFormErrorMessage({ error: formik?.errors?.password, touched: formik?.touched?.password })}
          errorVariant='text__form_valid_errors'
          labelVariant='label__form_input'
          label='Mật khẩu'
          fieldVariant='box__form_password'
          contentVariant='box__form_password_content'
          topVariant='box__form_top'
          trailingVariant='box__form_trailing'
          // note={
          //   <Button variant='btn__forgot_password' onClick={() => {}}>Forgot password?</Button>
          // }
          trailing={
            <Button
              bg='transparent !important'
              variant='ghost'
              type='button'
              onClick={(e) => {
                e.stopPropagation()
                setOpen(!open)
              }}
              sx={{
                margin: 0,
                minWidth: 0,
                width: '3rem',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& button': {
                  padding: 0,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }
              }}
            >
              {!open ? <AiOutlineEyeInvisible fontSize={20} color='#4d4c4c' />
                : <AiOutlineEye fontSize={20} color='#4d4c4c' />}
            </Button>
          }
        >
          <Input
            name='password'
            placeholder=''
            type={open ? 'text' : 'password'}
            value={formik?.values?.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            variant='input__form_password' />
        </FieldHOC>
        <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}>
        <Button
          key='form-btn-submit'
          type='submit'
          my={2}
          width={80}
          variant='button__form_submit'
        >
          Đăng nhập
        </Button>
      </Box>
      </Box>
    </Form>
  </Box>
  )
}

export default LoginForm
