export const input = {
    input__form_info: {
        border: '1px solid',
        margin: 0,
        display: 'block',
        width: '100%',
        mozAppearance: 'none',
        appearance: 'none',
        padding: '8px 0 8px 20px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: '.875rem',
        lineHeight: '1.25rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        borderColor: 'rgba(209,213,219,1)',
        borderWidth: '1px',
        borderRadius: '5px',
        height: '3rem',
        background: '#fff !important',
        color: 'rgba(31,41,55,1) !important',
        boxShadow: '0 0 0px 1000px white inset',
        '&:placeholder': {
            color: '#7d8db3',
            verticalAlign: 'bottom',
        },
        '&:focus-visible': {
            borderColor: 'rgba(0, 159, 127, 1)',
            outline: 'none',
        }
    },
    input__form_checkbox: {
        appearance: 'revert',
        width: 'fit-content',
        color: '#fff',
        input: {
            maxWidth: '20px',
            height: '20px',
            borderRadius: '5px',
        },
        '&:checked': {
            background: 'blue',
        }
    },
    input__form_password: {
        variant: 'text.input__form_info',
       boxShadow: '0 0 0px 1000px white inset',
        border: 'none',
        '&:focus-visible': {
            outline: 'none',
        }
    },
}