import { Empty, Modal, notification } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { AiOutlineClear } from "react-icons/ai";
import { Box } from "rebass"
import { SocketClient } from "../../../../../../socket/socketIo";
import useHomeContext from "../../../../logic/providers"
import './style.css'

const SystemLogs = () => {
    const { logs, setLogs, getLogs, currentDeviceID, deletedLogs } = useHomeContext()
    const {handleLogsEvent} = SocketClient()

    useEffect(() => {
        if (!localStorage.getItem("token") ) return
        handleLogsEvent(setLogs)

        return () => {
            setLogs([])
        }
    }, [])

    useEffect(() => {
        if (!currentDeviceID || !!logs?.length) return
        getLogs(currentDeviceID).then((data: any) => {
            if (!data?.logs?.length) return
            setLogs([...data.logs])
        })
    }, [currentDeviceID])

    useEffect(() => {
        if (!logs?.length) return

        document.getElementById(`log_0`)?.scrollIntoView(
            {
              behavior: 'smooth',
              block: 'nearest',
              inline: 'nearest'
            })
    }, [logs?.length])

    const onSubmit = () => {
        if (!currentDeviceID) return
        deletedLogs(currentDeviceID).catch((error: any) => {
            notification.error({
                message: 'Xóa logs chưa thành công!'
            })
        })
    }

    const onDeleted = () => {
        Modal.confirm({
            title: `Bạn muốn xóa logs ?`,
            onOk: () => {
                if (!currentDeviceID) return
                deletedLogs(currentDeviceID)
            },
            okText: 'Đồng ý',
            cancelText: 'Hủy'
        })
    }
    

    if (!logs?.length) {
        return (
            <Box variant='box__system_logs_empty'>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Box>
        )
    }
    
    return (
        <Box variant='box__system_logs'>
            <Box className="cleae-logs-icon" onClick={onDeleted}>
                <AiOutlineClear color='#727272'style={{ fontSize: '20px'}} />
            </Box>
            <ul id='logs_system'>
                {logs.map((log: any, idx: number) => {
                    return (
                        <li id={`log_${idx}`} key={idx}>
                            {log?.createdAt && `${moment(log?.createdAt).format('DD/MM/YY HH:mm:ss ') || '___'} 
                            :  ${log?.content?.name || ''} ${log?.content?.message || '___'}`}
                            </li>
                    )
                })}
            </ul>
        </Box>
    )
}

export default SystemLogs;
