import { FormikValues } from "formik";
import { useState } from "react";
import DeviceDetail from "./deviceDetail"
import DeviceSchedule from "./schedule/deviceSchedule";
import './style.css'

interface IProps {
    item: any;
    onOk: (data: any) => void;
    onCancel: () => void;
    initialValues?: FormikValues
}

const SettingButtonModal = ({onCancel, onOk, initialValues, item}: IProps) => {
    const [currTab,setCurTab] = useState('setting')
    const content: {[key: string]: any} = {
        setting: {
            label: "Setting",
            element: <DeviceDetail onCancel={onCancel} onOk={onOk} initialValues={initialValues} item={item} />
        },
        schedule: {
            label: "Schedules",
            element: <DeviceSchedule item={item} />
        }
    }

    const onChange = () => {
        setCurTab((prev: any) => {
            if (prev === 'setting') return 'schedule'

            return 'setting'
        })
    }

    return (
        <div className="setting-modal">
            <div className="setting-modal__tab">
                {Object.keys(content).map((val: any, idx: number) => {
                    return (
                        <div
                            className={`setting-modal__tab__item ${currTab === val && 'tab-active'}`} 
                            onClick={onChange}
                            key={idx}

                        >
                            {content[val]?.label}
                        </div>
                    )
                })}
            </div>
            <div className="setting-modal__content">
                {content[currTab].element}
            </div>
        </div>
    )
}

export default SettingButtonModal
