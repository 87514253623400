import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Box, Text } from "rebass"
import LoginForm from "./ui/login-form"

const Login = () => {
  const token = localStorage.getItem('token')
  const history = useHistory()

  // Đã login rồi -> chưa logout -> ko thể quay laijpage login
  useEffect(() => {
    if (!token?.length) {
      return
    }

    return history.push('/')
  }, [token])

  return (
    <Box variant='box__login'>
      <Box variant='box__login_content'>
        <Text variant='text__login_logo'>Agri Smart</Text>
        <Text aria-valuetext="">Hotline: 0939 150222</Text>
        <LoginForm />
      </Box>
    </Box>
  )
}

export default Login
