import { Input } from "@rebass/forms";
import { Button, notification } from "antd";
import { useFormik } from "formik"
import { useEffect } from "react";
import { Box, Text } from "rebass"
import * as Yup from 'yup'
import Form from "../../../../../components/form/form";
import FieldHOC from "../../../../../components/form/input";
import { getFormErrorMessage } from "../../../../../helpers/getFormErrorMessage";
import useHomeContext from "../../../logic/providers";
import './style.css'

interface IProps {
    onCancel: () => void
}


const validationSchema = Yup.object().shape({
    valveOpeningTime: Yup.number().required('Bắt buộc nhập').min(1, 'Giá trị phải lớn hơn 0'),
    repeat: Yup.number().required('Bắt buộc nhập'),
    valveStart: Yup.number().required('Bắt buộc nhập').min(1, 'Giá trị phải lớn hơn 0'),
    valveEnd: Yup.number().required('Bắt buộc nhập').moreThan(Yup.ref('valveStart'), "Van đến phải lớn hơn van bắt đầu"),
})


const SystemConfig = ({ onCancel }: IProps) => {
    const { updateDevice, currentDeviceID, getDeviceByID } = useHomeContext()

    const initialValues = {
        valveOpeningTime: 0,
        repeat: 0,
        valveStart: 0,
        valveEnd: 0,
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (data: any) => {
            const body = {
                irrConfig: {
                    valveOpeningTime: data?.valveOpeningTime,
                    repeat: data?.repeat,
                    valveStart: data?.valveStart,
                    valveEnd: data?.valveEnd,
                }
            }

            updateDevice(body, currentDeviceID || '').then((res: any) => {
                notification.info({
                    message: `Cập nhật cấu hình device thành công!`,
                    placement: 'topRight',
                });
            }).catch((_error: any) => {
                notification.error({
                    message: `Cập nhật cấu hình device chưa thành công`,
                    placement: 'topRight',
                });
            }).finally(onCancel)
        }
    })

    useEffect(() => {
        if (!currentDeviceID) return

        getDeviceByID(currentDeviceID).then((data: any) => {
            if (!data) return

            formik.setFieldValue('valveOpeningTime', data?.irrConfig?.valveOpeningTime || 0)
            formik.setFieldValue('repeat', data?.irrConfig?.repeat || 0)
            formik.setFieldValue('valveStart', data?.irrConfig?.valveStart || 0)
            formik.setFieldValue('valveEnd', data?.irrConfig?.valveEnd || 0)
        })

    }, [currentDeviceID])

    return (
        <Box variant='box__system_config'>
            <Form handleSubmit={formik.handleSubmit} >
                <Box variant='box__system_config_container'>
                    <Box variant='box__system_config_header'>
                        <Text variant='text__system_config_title'>Cấu hình: </Text>
                    </Box>
                    <Box variant='box__system_config_content'>
                        <Box>
                            <FieldHOC
                                name='valveOpeningTime'
                                id='valveOpeningTime'
                                errorMessage={getFormErrorMessage({ error: formik?.errors?.valveOpeningTime, touched: formik?.touched?.valveOpeningTime })}
                                errorVariant='text__form_valid_errors'
                                labelVariant='label__form_input'
                                label='Thời gian tưới: '
                            >
                                <Input
                                    name='valveOpeningTime'
                                    placeholder='0'
                                    type='number'
                                    pattern="^(0|[1-9][0-9]*)$"
                                    value={formik?.values?.valveOpeningTime}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    min={0}
                                    variant='input__form_info' />
                            </FieldHOC>
                        </Box>
                        <Box>
                            <FieldHOC
                                name='repeat'
                                id='repeat'
                                errorMessage={getFormErrorMessage({ error: formik?.errors?.repeat, touched: formik?.touched?.repeat })}
                                errorVariant='text__form_valid_errors'
                                labelVariant='label__form_input'
                                label='Lượt lặp: '
                            >
                                <Input
                                    name='repeat'
                                    placeholder='0'
                                    type='number'
                                    value={formik?.values?.repeat}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    min={0}
                                    variant='input__form_info' />
                            </FieldHOC>
                        </Box>
                        <Box>
                            <FieldHOC
                                name='valveStart'
                                id='valveStart'
                                errorMessage={getFormErrorMessage({ error: formik?.errors?.valveStart, touched: formik?.touched?.valveStart })}
                                errorVariant='text__form_valid_errors'
                                labelVariant='label__form_input'
                                label='Từ Van: '
                            >
                                <Input
                                    name='valveStart'
                                    placeholder='0'
                                    type='number'
                                    value={formik?.values?.valveStart}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    min={0}
                                    variant='input__form_info' />
                            </FieldHOC>
                        </Box>
                        <Box>
                            <FieldHOC
                                name='valveEnd'
                                id='valveEnd'
                                errorMessage={getFormErrorMessage({ error: formik?.errors?.valveEnd, touched: formik?.touched?.valveEnd })}
                                errorVariant='text__form_valid_errors'
                                labelVariant='label__form_input'
                                label='Đến Van'
                            >
                                <Input
                                    name='valveEnd'
                                    placeholder='0'
                                    type='number'
                                    value={formik?.values?.valveEnd}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    min={0}
                                    variant='input__form_info' />
                            </FieldHOC>
                        </Box>
                    </Box>
                    <Box variant='box__system_config_footer' className="box__system_config_footer">
                        <Button className="btn__system_config_reset" onClick={(e: any) => {
                            onCancel()
                            formik.handleReset(e)
                        }}>Hủy</Button>
                        <Button
                            className="btn__system_config_save"
                            onClick={(e: any) => {
                                e.preventDefault()
                                formik.handleSubmit()
                                e.stopPropagation()
                            }}
                        >
                            Lưu
                        </Button>
                    </Box>
                </Box>
            </Form>
        </Box>
    )
}

export default SystemConfig;
