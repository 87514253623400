import { Box, Text } from "rebass"
import { DeviceButton } from "./deviceButton"
import DeviceDetail from "./Modal/deviceDetail"
import { useCallback, useLayoutEffect, useRef, useState } from "react"
import { AiOutlineSetting } from "react-icons/ai"
import { Modal, notification } from "antd"
import useHomeContext from "../../logic/providers"
import { SOCKET_EVENT } from "../../../../constant"
import SettingButtonModal from "./Modal/settingModal"
import { SocketClient } from "../../../../socket/socketIo"


const DeviceItem = ({ item, index }: { item?: any, index: number }) => {
  const [isShow, setIsShow] = useState(false)
  const { updateButton, socket, controlButtons, setControlButtons, setLogs, currTap } = useHomeContext()
  const [active, setActive] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>()

  const {socketIO} = SocketClient()

  const onCancel = () => {
    setIsShow(false)
  }

  const onOK = (data: any) => {
    setIsShow(false)

    const body: { [key: string]: any } = {
      buttonID: item?.buttonID || '',
      buttonName: data?.buttonName || '',
      // icon: data?.icon || null,
      valueOn: data?.valueOn || 0,
      valueOff: data?.valueOff || 0,
      eventName: data?.eventName || '',
      tab: currTap,
    }

    updateButton(body).then((res: any) => {
      if (!!res?.data?.result) {
        notification.info({
          message: `Cập nhật thành công!`,
          placement: 'topRight',
        });
      }
    }).catch((error: any) => {
      if (error.response) {
        notification.error({
          message: `${error.response.data?.error}`,
          placement: 'topRight',
        });
      }
    })
  }

  const updateBtnStatus = (status: string) => {
    const tempControlButtons = controlButtons.map((btn: any) => {
      if (btn?.buttonID === item?.buttonID) {
        return {
          ...btn,
          status,
        }
      }

      return btn
    }) || []

    setControlButtons([...tempControlButtons])
  }

  const onClickAction = (e: any, currentStus: boolean) => {
    e.preventDefault()
    setActive(!currentStus)

    if ([SOCKET_EVENT.START, SOCKET_EVENT.STOP].includes(item.eventName)) {

      const temp = {
        buttonName: item?.buttonName,
        value: null,
        status: null,
        log: {
          message: SOCKET_EVENT.START ? "Bắt đầu tưới" : "Đã dừng hệ thống"
        }
      }
      socketIO?.emit(item.eventName, temp)

      return
    }

    if (!currentStus) {
      updateBtnStatus('ON')

      const temp = {
        buttonID: item?.buttonID,
        buttonName: item?.buttonName,
        value: item?.valueOn || 0,
        status: "ON",
        log: {
          message: `${item?.buttonName} đã được bật`
        }
      }


      socketIO?.emit(item.eventName, temp)
      const newTemp = {
        content: {
          message: `${item?.buttonName} đã được bật`,
          eventName: 'Thao tác',
          buttonName: item?.buttonName,
        },
        createdAt: new Date(),
      }
      setLogs((prevState: any[], _props: any) => {

        if (!prevState || !prevState?.length) {
          return [newTemp]
        }

        const temps = [newTemp, ...prevState]
        // temps.push(newTemp)

        return temps
      })

      return
    }

    updateBtnStatus('OFF')

    const temp = {
      buttonID: item?.buttonID,
      buttonName: item?.buttonName,
      value: item?.valueOff || 0,
      status: "OFF",
      log: {
        message: `${item?.buttonName} đã được tắt`
      }
    }


    socketIO?.emit(item.eventName, temp)
    const newTemp = {
      content: {
        message: `${item?.buttonName} đã được tắt`,
        buttonName: item?.buttonName,
        eventName: 'Thao tác',

      },
      createdAt: new Date(),
    }

    socketIO?.emit(SOCKET_EVENT.WEB_LOG, newTemp)
    setLogs((prevState: any[], _props: any) => {
      if (!prevState || !prevState?.length) {
        return [newTemp]
      }

      const temps = [newTemp, ...prevState]

      return temps
    })
  }

  const onResize = useCallback(() => {
    const width = document.getElementById('box__layout')?.getBoundingClientRect().width || 480;
    const containerWith = width <= 480 ? width : 480
    const btnWidth = containerWith / 2 - 15

    if (ref.current) {
      ref.current.style.width = `${btnWidth}px`
    }
  }, [])

  useLayoutEffect(() => {
    onResize();
    window.addEventListener('orientationchange', onResize);
    window.addEventListener('resize', onResize);
  }, [onResize, ref.current])


  return (
    <Box ref={ref} variant={`box__device_${index}`}>
      <Box variant='box__device_action' onClick={() => setIsShow(true)}>
        <AiOutlineSetting color='#727272' style={{ fontSize: '18px' }} />
      </Box>
      <Box variant='box__device_left'>
        <Text variant='text__device_title'>{item?.buttonName || ''}</Text>
      </Box>
      <Box variant='box__device_right'>
        <DeviceButton status={item?.status=== 'ON'} onClick={(e: any) => onClickAction(e, item?.status ==='ON')} icon={<></>} />
      </Box>
      <Modal onCancel={onCancel} open={isShow} footer={null}>
        <SettingButtonModal onOk={onOK} item={item} onCancel={onCancel} />
      </Modal>
    </Box>
  )
}

export default DeviceItem