
import React, { createContext, useCallback, useContext, useState } from 'react'
// import { useHistory } from 'react-router-dom'
import { IAuthContextProps } from '../../../types'
import { onGetProfile, onLogin } from './api'

export const AuthContext = createContext<IAuthContextProps | null>(null)

export const AuthProvider: React.FC = ({ children }) => {

  const [profile, setProfile] = useState<{[key: string]: any}>({})

  const login = useCallback( (body: any) => {
      return onLogin(body).then((res: any) => {
      if (res?.status !== 200) {
        return
      }

      localStorage.setItem('token', res?.data?.token)
      return res?.data?.token
    })
  }, [])

  const getProfile = useCallback((token: string) => {
   return  onGetProfile(token).then((res: any) => {
      if (res?.status !== 200 && !res?.data?.id) {
        localStorage.removeItem('token')

        return
      }

      setProfile(res?.data)

      return res?.data
    })
  }, [profile])


  return (
    <AuthContext.Provider
      value={{
        login,
        profile,
        setProfile,
        getProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default function useAuthContext() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider')
  }

  return context
}