import { Button, Select } from "antd";
import { useFormik } from "formik"
import { useEffect } from "react";
import { Box, Text } from "rebass"
import * as Yup from 'yup'
import Form from "../../../../../../components/form/form";
import FieldHOC from "../../../../../../components/form/input";
import { SOCKET_EVENT } from "../../../../../../constant";
import { SocketClient } from "../../../../../../socket/socketIo";
import useHomeContext from "../../../../logic/providers";
import './style.css'
const { Option } = Select;

interface IProps {
    onOk: () => void;
    onCancel: () => void;
}


const validationSchema = Yup.object().shape({
    deviceID: Yup.string().required('Vui lòng chọn 1 thiết bị!'),
})


const ChooseDevices = ({ onOk, onCancel }: IProps) => {
    const { getDevices, devices, setCurrentDeviceID, currentDeviceID, setLogs, } = useHomeContext()
    const {socketIO} = SocketClient()

    useEffect(() => {
        getDevices()
    }, [])

    const formik = useFormik({
        initialValues: {
            deviceID: currentDeviceID,
        },
        validationSchema,
        onSubmit: (data: any) => {
            if (!data?.deviceID) return

            socketIO?.emit(SOCKET_EVENT.UPDATE_CURRENT_DEVICE, data?.deviceID)
            setLogs([])
            setCurrentDeviceID(data?.deviceID)

            // const info = {
            //     ample: '0',
            //     h: '0',
            //     hz: '0',
            //     t: '0',
            //     vol: '0',
            //     watt: '0'
            // }

            // setDeviceInfo(info)
            onOk()
        }
    })

    const oDeviceChange = (value: any) => {
        if (value === formik.values?.deviceID) return

        formik.setFieldValue('deviceID', value)
    }

    return (
        <Box variant='box__system_config'>
            <Form handleSubmit={formik.handleSubmit} >
                <Box variant='box__devices_container'>
                    <Box variant='box__system_config_header'>
                        <Text variant='text_devices_title'>Thiết bị: </Text>
                    </Box>
                    <Box variant='box_devices_content' className="box_devices_content">
                        <FieldHOC
                            name='deviceID'
                            id='deviceID'
                            errorMessage={formik?.errors?.deviceID || ''}
                            errorVariant='text__form_valid_errors'
                            labelVariant='label__form_input'
                        >
                            <div className="wrapper">
                                <Select
                                    id='deviceID'
                                    defaultValue={formik?.values?.deviceID}
                                    className="slelect__devices"
                                    disabled={!devices?.length}
                                    allowClear
                                    onChange={oDeviceChange}
                                >
                                    {devices?.map((item: any, idx: number) => (
                                        <Option
                                            key={item?.deviceID || idx} value={item?.deviceID || ''}>
                                            {item?.deviceName}
                                        </Option>
                                    ))}
                                </Select>
                            </div>

                        </FieldHOC>

                    </Box>
                    <Box variant='box__device_item_footer' className="box___device_item_footer">
                        <Button className="btn__system_config_reset" onClick={(e: any) => {
                            onCancel()
                            // formik.handleReset(e)
                        }}>Hủy</Button>

                        <div className="btn_system_button_control_right">
                            {/* <Button className="btn__system_config_delete" onClick={(e: any) => {
                                e.preventDefault()
                                // onDeleted(item?.buttonID || '')
                            }}>Xóa</Button> */}
                            <Button
                                className="btn__system_config_save"
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    formik.handleSubmit()
                                    e.stopPropagation()
                                }}
                            >
                                Lưu
                            </Button>
                        </div>
                    </Box>
                </Box>
            </Form>
        </Box>
    )
}

export default ChooseDevices;
