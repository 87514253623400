import { Box, Text } from "rebass"

interface IProps {
    icon: any;
    label: string;
    value: number | string;
    color: string;
}

const HeaderSystemNumberItem = ({ icon, label, value, color }: IProps) => {
    return (
        <Box variant='box__header_system_item'>
            <Box variant='box__header_system_item_left'>
              {icon}
                <Text variant='text__header_system_item_label'>{label}</Text>
            </Box>
            <Box variant='box__header_system_item_right'>
                <Text variant='text__header_system_item_value'  color={color}>{value}</Text>
            </Box>
        </Box>
    )
}

export default HeaderSystemNumberItem
