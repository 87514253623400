import React, { createContext, useContext, useState } from 'react'
import { IHome2ContextProps } from '../../../types'

export const HomeContext2 = createContext<IHome2ContextProps | null>(null)

export const HomeProvider2: React.FC = ({ children }) => {
  const [title, setTitle] = useState('')

  return (
    <HomeContext2.Provider
      value={{
        title,
        setTitle,
      }}
    >
      {children}
    </HomeContext2.Provider>
  )
}

export default function useHome2Context() {
  const context = useContext(HomeContext2)
  if (!context) {
    throw new Error('useHomeContext must be used within a HomeProvider')
  }

  return context
}
