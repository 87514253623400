import { Route, Switch } from 'react-router-dom'
import { ProviderHoC } from './provider_hoc'
import { IRouteHoC } from '../types'

export const RouteHoC: React.FC<IRouteHoC> = ({ ErrorPage, routes, providers }) => (
  <ProviderHoC providers={providers}>
    <Switch>
      {routes.map((item, idx) => {
        return (
          <Route key={idx} path={item?.path || '/'} exact={item?.isExact || false}>
            <item.component />
          </Route>
        )
      })}
      <Route>
        {ErrorPage && <ErrorPage />}
      </Route>
    </Switch>
  </ProviderHoC>
)
