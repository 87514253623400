import axios from "axios"
import { host } from "../../../constant"


export const onGetDevices = async (params: any) => {
    try {

      const token = localStorage.getItem('token')
      const res = await axios.get(
        `${host}/device/me`, {
        params,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

        return {
          data: res?.data,
          status: res?.status,
          statusText: res?.statusText,
        }
      } catch (error) {
        throw error
      }
} 

export const onCreateDevice = async (data: any) => {
  try {

    const token = localStorage.getItem('token')
    const res = await axios.post(
      `${host}/device`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {
      status: res?.status,
      statusText: res?.statusText,
    }
  } catch (error) {
    throw error
  }
}

export const onUpdateDevice = async (data: any, deviceID: any) => {
  try {
    const token = localStorage.getItem('token')
    const body = {

    }
    const res = await axios.put(
      `${host}/device/${deviceID}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {
      status: res?.status,
      statusText: res?.statusText,
    }
  } catch (error) {
    throw error
  }
}

export const onGetControlButton = async ({ deviceID, tab}: { deviceID: string, tab: string}) => {
  try {

    const token = localStorage.getItem('token')
    const res = await axios.get(
      `${host}/button?deviceID=${deviceID}&tab=${tab}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {
      data: res?.data,
      status: res?.status,
      statusText: res?.statusText,
    }
  } catch (error) {
    throw error
  }
}

export const onCreateControlButton = async (data: any) => {
  try {

    const token = localStorage.getItem('token')
    const res = await axios.post(
      `${host}/button`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {
      data: res?.data,
      status: res?.status,
      statusText: res?.statusText,
    }
  } catch (error) {
    throw error
  }
}

export const onUpdateControlButton = async (data: any) => {
  try {

    const token = localStorage.getItem('token')
    const res = await axios.put(
      `${host}/button`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {
      status: res?.status,
      data: res?.data,
      statusText: res?.statusText,
    }
  } catch (error) {
    throw error
  }
}

export const onControlButtonDelete = async (btnID: string) => {
  try {

    const token = localStorage.getItem('token')
    const res = await axios.delete(
      `${host}/button/${btnID}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {
      status: res?.status,
      statusText: res?.statusText,
    }
  } catch (error) {
    throw error
  }
}

export const onGetLogs = async (deviceID: string) => {
  try {

    const token = localStorage.getItem('token')
    const res = await axios.get(
      `${host}/logs?deviceID=${deviceID}&sortDirection=DESC`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {
      data: res?.data,
      status: res?.status,
      statusText: res?.statusText,
    }
  } catch (error) {
    throw error
  }
}

export const onDeletedtLogs = async (deviceID: string) => {
  try {

    const token = localStorage.getItem('token')
    const res = await axios.delete(
      `${host}/logs/${deviceID}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return res
  } catch (error) {
    throw error
  }
}

export const onGetSchedules = async ({btnID, currentDeviceID}: any) => {
  try {
    const token = localStorage.getItem('token')
    const res = await axios.get(
      `${host}/schedule-device/${currentDeviceID}?buttonID=${btnID}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {
      data: res?.data,
      status: res?.status,
      statusText: res?.statusText,
    }
  } catch (error) {
    throw error
  }
}

export const onUpdateSchedules = async ({scheduleID,  data}: any) => {
  try {
    const token = localStorage.getItem('token')
    const res = await axios.put(
      `${host}/schedule-device/${scheduleID}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {
      data: res?.data,
      status: res?.status,
      statusText: res?.statusText,
    }
  } catch (error) {
    throw error
  }
}

export const onDeletedtSchedule = async ({scheduleID}: any) => {
  try {

    const token = localStorage.getItem('token')
    const res = await axios.delete(
      `${host}/schedule-device/${scheduleID}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return res
  } catch (error) {
    throw error
  }
}

export const onCreateSchedules = async ({data}: any) => {
  try {
    const token = localStorage.getItem('token')
    const res = await axios.post(
      `${host}/schedule-device`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return {
      data: res?.data,
      status: res?.status,
      statusText: res?.statusText,
    }
  } catch (error) {
    throw error
  }
}