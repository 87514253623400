export const convertNumber = (value: string) => {
    if (!value) {
        return 0;
    }

    if (typeof value === 'string' && value?.includes('.')) {
        return Math.round(Number(value))
    }

    const number = Math.round(Number(value))

    return number;
}