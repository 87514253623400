import { useEffect } from "react"
import { SocketClient } from "../../../../socket/socketIo"
import Devices from "./ui/devices/devices"
import HeaderSystemNumber from "./ui/header/header-system-number"
import SystemLogs from "./ui/logs/system-logs"

const Home = () => {
    return (
        <>
            <HeaderSystemNumber />
            <div style={{ width: '100%' }}>
                <SystemLogs />
                <Devices />
            </div>
        </>
    )
}

export default Home
