import { box } from './box';
import { button } from './button';
import { input } from './input';
import { label } from './label';
import { preset } from './preset';
import { radio } from './radio';
import { select } from './select';
import { text } from './text';
import { textarea } from './textarea';

export const theme = {
    ...preset,
    breakpoints: ['768px', '1024px'],
    // rebass variants
    text: {
        heading: {
            fontFamily: 'heading',
            lineHeight: 'heading',
            fontWeight: 'heading',
        },
        display: {
            fontFamily: 'heading',
            fontWeight: 'heading',
            lineHeight: 'heading',
            fontSize: [5, 6, 7],
        },
        ...text,
    },
    variants: {
        ...box,
    },
    ...input,
    ...select,
    ...label,
    ...radio,
    ...textarea,
    buttons: {
        primary: {
            fontSize: 2,
            fontWeight: 'bold',
            color: 'background',
            bg: 'primary',
            borderRadius: 'default',
        },
        outline: {
            variant: 'buttons.primary',
            color: 'primary',
            bg: 'transparent',
            boxShadow: 'inset 0 0 2px',
        },
        secondary: {
            variant: 'buttons.primary',
            color: 'background',
            bg: 'secondary',
        },
        ...button,
    },
    styles: {
        root: {
            fontFamily: 'body',
            fontWeight: 'body',
            lineHeight: 'body',
        },
    },
}