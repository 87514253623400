import { useCallback, useEffect, useState } from "react"
import { Box } from "rebass"
import ChooseDevices from "./Modal/Devices"
import {  AiOutlineHome, AiOutlineLogin, AiOutlineMenu } from "react-icons/ai"
import { IoGitNetwork } from "react-icons/io5";

import { useHistory, useLocation } from "react-router-dom"
import { TAB_TYPE_ENUM } from "../../../../../constant"
import useHomeContext from "../../../logic/providers"
import { Modal } from "antd"
import { BiChip } from "react-icons/bi";

const Footer = () => {
    const [isChooseDevice, setIsChooseDevice] = useState(false)
    const { pathname } = useLocation()
    const history = useHistory()
    const { setCurrTap, currTap, setControlButtons } = useHomeContext()

    const onLogout = useCallback(() => {
        const token = localStorage.getItem('token')
        const isLoginPage = pathname?.includes('/login')
        if (!token || isLoginPage) return

        localStorage.removeItem('token');
        history.push('/login')
    }, [])

    const onChangePage = (type: string) => {
        const currentType = localStorage.getItem('tap')
        if (currentType === type) return

        setCurrTap(type)
        setControlButtons([])
       localStorage.setItem('tap', type)
    }

    return (
        <Box variant='box__footer'>
            <Box variant='box__footer_left'>
                <a onClick={() => setIsChooseDevice(true)}>
                    <AiOutlineMenu color='#ccc' fontSize={36} />
                </a>
            </Box>
            <Box variant='box__footer_center' >
                <a onClick={() => onChangePage(TAB_TYPE_ENUM.HOME)}>
                    <AiOutlineHome color={currTap === TAB_TYPE_ENUM.HOME ? '#53c8bc' : '#ccc'} fontSize={36} />
                </a>

            </Box>
            <Box variant='box__footer_center' >
                <a onClick={() => onChangePage(TAB_TYPE_ENUM.TRAM)}>
                    <IoGitNetwork color={currTap === TAB_TYPE_ENUM.TRAM ? '#53c8bc' : '#ccc'} fontSize={36} />
                </a>

            </Box>
            <Box variant='box__footer_center' >
                <a onClick={() => onChangePage(TAB_TYPE_ENUM.THIETBI)}>
                    <BiChip color={currTap === TAB_TYPE_ENUM.THIETBI ? '#53c8bc' : '#ccc'} fontSize={36} />
                </a>

            </Box>
            <Box variant='box__footer_center'>
                <div onClick={onLogout}>
                    <AiOutlineLogin color='#ccc' fontSize={36} />
                </div>

            </Box>
            <Modal onCancel={() => setIsChooseDevice(false)} open={isChooseDevice} footer={null} >
                <ChooseDevices onOk={() => setIsChooseDevice(false)} onCancel={() => setIsChooseDevice(false)} />
            </Modal>
        </Box>
    )
}

export default Footer
