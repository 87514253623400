import { useEffect, useState } from "react"
import { AiOutlineFontColors, AiOutlineThunderbolt } from "react-icons/ai"
import { Box, Text } from "rebass"
import { convertNumber } from "../../../../../../helpers/number"
import { SocketClient } from "../../../../../../socket/socketIo"
import { IInfo } from "../../../../../../types"
import HeaderSystemNumberItem from "./header-sytem-number-item"

const HeaderSystemNumber = () => {
    const {handleDeviceInfoEvent} = SocketClient()
    const [deviceInfo, setDeviceInfo] =useState<IInfo>({
        ample: '0',
        h: '0',
        hz: '0',
        t: '0',
        vol: '0',
        watt: '0'
      })
    
    useEffect(() => {
        if (!localStorage.getItem("token") ) return
       handleDeviceInfoEvent(setDeviceInfo)

        return () => {
            setDeviceInfo({
                ample: '0',
                h: '0',
                hz: '0',
                t: '0',
                vol: '0',
                watt: '0'
            })
        }
    }, [])

    return (
        <Box variant='box__header_system'>
            <HeaderSystemNumberItem icon={<Text fontSize={12} color='#e31a1a' fontWeight={600}>Vol</Text>} label='V' value={convertNumber(deviceInfo?.vol)}  color='#e31a1a'/>
            <HeaderSystemNumberItem icon={<AiOutlineFontColors fontSize={16} color='#1c11dc' />} label='A' value={Number(deviceInfo?.ample || 0).toFixed(1)}  color='#1c11dc'/>
            <HeaderSystemNumberItem icon={<AiOutlineThunderbolt fontSize={16} color='#0e79f2' />} label='Kw/h' value={Number(deviceInfo?.watt || 0).toFixed(1)}  color='#0e79f2'/>
            <HeaderSystemNumberItem icon={<Text fontSize={12} color='#cdbe28' fontWeight={600}>Hz</Text>} label='Hz' value={convertNumber(deviceInfo?.hz)}  color='#cdbe28'/>
            <HeaderSystemNumberItem icon={<Text fontSize={12} color='#b7b4b4' fontWeight={600}>T</Text>} label='C' value={Number(deviceInfo?.t || 0).toFixed(1)}  color='#b7b4b4'/>
            <HeaderSystemNumberItem icon={<Text fontSize={12} color='#b7b4b4' fontWeight={600}>H</Text>} label='%' value={convertNumber(deviceInfo?.h)}  color='#b7b4b4'/>          
        </Box>
    )
}

export default HeaderSystemNumber
