export const button = {
  default: {
    backgroundColor: '#F00',
    backgroundImage: 'none',
    color: 'white',
  },
  second: {
    variant: 'default',
    backgroundColor: '#0F0',
  },
  rounded: {
    variant: 'default',
    borderRadius: '100px',
  },
  button__form: {
    backgroundColor: '#07c',
    p: '10px 0',
    cursor: 'pointer',
    fontStyle: 'normal',
    fontSize: ['14px', '14px', '16px'],
    borderRadius: '5px',
    fontWeight: 600,
    border: '1px solid',
    '&:disabled': {
      backgroundColor: '#ccc'
    }
  },
  button__form_submit: {
    width:'fit-content !important',
    padding: '13px 10px',
    background: '#fff',
    color: '#00ab55',
    fontWeight: 600,
    fontSize: '18px',
    borderRadius: '5px',
    boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px',
    '&:disabled': {
      backgroundColor: '#ccc',
      color: '#828eaa',
      cursor: 'not-allowed',
    },
    '&:hover': {
      boxShadow: '#00ab55 0px 1px 1px, #00ab58 0px 0px 1px 1px',
    }
  },
  btn__forgot_password: {
    transitionDuration: '.2s',
    cursor: 'pointer',
    transitionProperty: 'color,background-color,border-color,text-decoration-color,fill,stroke,-webkit-text-decoration-color',
    transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
    color: 'rgba(0,159,127, 1)',
    fontSize: '.75rem',
    lineHeight: '1rem',
    webkitAppearance: 'button',
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    minWidth: 'fit-content',
    '&:focus': {
      fontWeight: 600,
      color: 'rgba(0,119,95,1)'
    }
  },
  btn__menu_action: {
    background: 'transparent',
    color: '#1b978c',
    fontSize: '14px',
    fontWeight: 300,
    border: '1px solid #1b978c',
    cursor: 'pointer',
    padding: '10px',
    display: 'flex',
    gap: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'lowercase',
    borderRadius: '15px'
  },
  btn__menu_action_stop: {
    variant: 'buttons.btn__menu_action',
    borderColor: '#ebbdbd',
    color: '#b72424',
    '&:disabled': {
      borderColor: '#b3b3b3',
      color: '#666666',
      background: '#b3b3b3',
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    '&:hover': {
      borderColor: '#ebbdbd',
      color: '#cb3030',
      background: '#ebbdbd',
    }
  },
  btn__menu_action_stop_active: {
    variant: 'buttons.btn__menu_action',
    borderColor: '#ebbdbd',
    color: '#cb3030',
    background: '#ebbdbd',
  },
  btn__menu_action_lock: {
    variant: 'buttons.btn__menu_action',
    borderColor: '#b3b3b3',
    color: '#666666',
    '&:disabled': {
      borderColor: '#b3b3b3',
      color: '#666666',
      background: '#b3b3b3',
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    '&:hover': {
      background: '#d9d9d9',
    }
  },
  btn__menu_action_lock_active: {
    variant: 'buttons.btn__menu_action_lock',
    background: '#d9d9d9',
  },
  btn__menu_action_start: {
    variant: 'buttons.btn__menu_action',
    borderColor: '#329943',
    '&:disabled': {
      borderColor: '#b3b3b3',
      color: '#666666',
      background: '#b3b3b3',
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    '&:hover': {
      backgroundColor: '#9dd7a7',
      borderColor: '#9dd7a7',
    }
  },
  btn__menu_action_start_active: {
    variant: 'buttons.btn__menu_action_start',
    background: '#9dd7a7'
  },
  btn__header_config: {
    padding: '7px 9px',
    color: '#91979b',
    background: '#fff',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '4px',
    boxShadow: 'rgb(0 0 0 / 2%) 0px 1px 3px 0px, rgb(27 31 35 / 15%) 0px 0px 0px 1px',

    'img': {
      width: '18px',
      height: '18px'
    }
  },
  btn__modal_close: {
    position: 'absolute',
    right: '16px',
    top: '12px',
    borderRadius: '16px',
    background: '#ddd',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    cursor: 'pointer',
  },
  btn_system_config: {
    color:'#5e6173',
        borderRadius: '10px',
        padding: '5px 16px',
        boxShadow: 'rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 8%) 0px 0px 0px 1px',
        background: '#ddd',
  },
  btn__system_config_reset: {
    variant: 'buttons.btn_system_config'
  },
  btn__system_config_save: {
    variant: 'buttons.btn_system_config',
    background: '#206affc4',
    padding: '6px 16px',
    color: '#fff',
  },
  btn_device_save: {
    width:' 100%',
    padding: '13px 10px',
    background: '#fff',
    color: '#00ab55',
    fontWeight: 600,
    fontSize: '18px',
    borderRadius: '5px',
    boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px',
  }

}